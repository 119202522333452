import { BarChart, Header, SpaceBetween } from '@cloudscape-design/components';

import { GraphMessage } from '../common/chat/types';
import { formatBig, formatFinancial, getDateFormatter } from '../common/formatting';

export type ChatGraphProps = {
  message: GraphMessage;
};

export function ChatGraph(props: ChatGraphProps) {
  const message = props.message;
  // TODO support line charts
  // TODO support pie charts
  // TODO additional bar chart support:
  //  TODO support stackedBars?: boolean;
  //  TODO support horizontalBars?: boolean;
  let chart = undefined;

  if (message.x_type === 'datetime') {
    const x = message.x.map((v) => new Date((v as string) + 'Z'));
    const dateFormatter = getDateFormatter(x);
    chart = (
      <BarChart
        series={[
          ...message.series.map((s) => ({
            title: s.label,
            type: 'bar' as const,
            data: x.map((v, i) => ({ x: v, y: s.y[i] as number })), // objects with x and y
            valueFormatter: (value: number) =>
              s.type === 'currency' ? formatFinancial(value, 0) : formatBig(value),
          })),
        ]}
        // 'linear' | 'log' | 'time' | 'categorical'
        height={200}
        statusType="finished"
        xTitle={message.x_label}
        yTickFormatter={(value: number) =>
          message.series[0].type === 'currency' ? formatFinancial(value, 0) : formatBig(value)
        }
        yTitle={message.y_label}
        emphasizeBaselineAxis
        hideFilter
        xScaleType="categorical"
        // TODO datetime x axis needs more formatting, use heuristics to determine level of detail
        xTickFormatter={(e) => dateFormatter(e).split(',').join('\n')}
      />
    );
  } else if (message.x_type === 'number' || message.x_type === 'currency') {
    const x = message.x.map((v) => v as number);
    chart = (
      <BarChart
        height={200}
        series={[
          ...message.series.map((s) => ({
            title: s.label,
            type: 'bar' as const,
            data: x.map((v, i) => ({ x: v, y: s.y[i] as number })), // objects with x and y
            valueFormatter: (value: number) =>
              s.type === 'currency' ? formatFinancial(value, 0) : formatBig(value),
          })),
        ]}
        statusType="finished"
        yTickFormatter={(value: number) =>
          message.series[0].type === 'currency' ? formatFinancial(value, 0) : formatBig(value)
        }
        // 'linear' | 'log' | 'time' | 'categorical'
        xScaleType="categorical"
        xTickFormatter={(e) => e.toLocaleString()}
        xTitle={message.x_label}
        yTitle={message.y_label}
        emphasizeBaselineAxis
        hideFilter
      />
    );
  } else if (message.x_type === 'string') {
    const x = message.x.map((v) => v as string);
    chart = (
      <BarChart
        height={200}
        series={[
          ...message.series.map((s) => ({
            title: s.label,
            type: 'bar' as const,
            data: x.map((v, i) => ({ x: v, y: s.y[i] as number })), // objects with x and y
            valueFormatter: (value: number) =>
              s.type === 'currency' ? formatFinancial(value, 0) : formatBig(value),
          })),
        ]}
        yTickFormatter={(value: number) =>
          message.series[0].type === 'currency' ? formatFinancial(value, 0) : formatBig(value)
        }
        // 'linear' | 'log' | 'time' | 'categorical'
        statusType="finished"
        xScaleType="categorical"
        xTitle={message.x_label}
        yTitle={message.y_label}
        emphasizeBaselineAxis
        hideFilter
      />
    );
  } else {
    console.error('Unsupported x type');

    chart = (
      <BarChart
        errorText="Unsupported data type"
        height={200}
        series={[]}
        statusType="error"
        xTitle={message.x_label}
        yTitle={message.y_label}
        emphasizeBaselineAxis
        hideFilter
      />
    );
  }

  return (
    <SpaceBetween direction="vertical" size="xs">
      <Header variant="h2">{message.title}</Header>
      {chart}
    </SpaceBetween>
  );
}
