import {
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  Container,
  SpaceBetween,
  Spinner,
  Box,
  Alert,
  Button,
} from '@cloudscape-design/components';
import { createRef, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import { apiUrl } from '../common/api';

import './chat.css';
import { useChat } from '../common/chat/api';

import { ChatBar } from './chatbar';
import { ChatTurn } from './turn';

export default function Chat() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const navigate = useNavigate();

  const params = useParams();

  // Chat UUID
  const [chatId, setChatId] = useState(params.chatId);

  // old chat stuff was here
  const {
    chat,
    messages,
    error,
    loading,
    awaiting,
    streaming,
    getChat,
    pollResponse,
    streamResponse,
    addMessage,
    resetChat,
  } = useChat(apiUrl);

  useEffect(() => {
    if (!params.chatId) {
      console.log('no chat id');
      resetChat();
      setChatId(undefined);
    }
  }, [params]);

  useEffect(() => {
    if (chat && (!chatId || chat.uuid !== chatId)) {
      setChatId(chat.uuid);
      // update URL without reloading or navigating
      navigate(`/chat/${chat.uuid}`, { replace: true });
    }
  }, [chat, chatId]);

  useEffect(() => {
    streamResponse();
  }, [awaiting, chat, streaming, streamResponse]);

  // recurring poll
  useEffect(() => {
    const pollInterval = setInterval(async () => {
      // TODO call poll function
      await pollResponse();
    }, 1000);

    return () => clearInterval(pollInterval);
  }, [chat, pollResponse]);

  useEffect(() => {
    if (chatId && (!chat || chat.uuid !== chatId)) {
      console.log(chatId);
      console.log(chat);
      console.log(chat?.uuid);
      getChat(chatId);
    }
  }, [chatId, chat]);

  const chatContentRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current?.scrollHeight;
    }
  }, [messages]);

  const retry = useCallback(() => {
    if (chatId) {
      getChat(chatId);
    }
  }, [chatId, getChat]);

  return (
    <AppLayout
      className="app-layout"
      content={
        <div className="chat-container">
          <div ref={chatContentRef} className="chat-content">
            <Grid
              gridDefinition={[{ colspan: { default: 12, m: 8 }, offset: { default: 0, m: 2 } }]}
            >
              {(loading || error) && !chat ? (
                <GetLoadingOrError error={error} loading={loading} retry={retry} />
              ) : (
                <div>
                  <SpaceBetween direction="vertical" size="xs">
                    {messages.length > 0 ? (
                      messages
                        .toReversed()
                        .map((item, index) => (
                          <ChatTurn
                            key={`turn-${index}`}
                            addMessage={addMessage}
                            index={index}
                            loading={loading || awaiting}
                            message={item}
                          />
                        ))
                    ) : (
                      <div key="turn-div-suggest">
                        <ChatTurn
                          key="turn-suggest"
                          addMessage={addMessage}
                          index={0}
                          loading={loading || awaiting}
                          message={{
                            type: 'text',
                            created_at: new Date().toISOString().split('.')[0],
                            role: 'assistant',
                            message:
                              "Hi there! I am an AI assistant capable of answering detailed questions about Zappy's data. How can I help you today?",
                            options: [
                              {
                                title: 'Graph the revenue from each venue type',
                                subtitle: 'as a pie chart.',
                                value: 'Graph the revenue from each venue type as a pie chart.',
                              },
                              {
                                title: 'List the top venues',
                                subtitle: 'by revenue and rental minutes.',
                                value: 'List the top venues by revenue and rental minutes.',
                              },
                              {
                                title: 'Graph Kiosk and Uber',
                                subtitle: 'revenue as separate series for each month.',
                                value:
                                  'Graph the kiosk and uber revenue as separate series for each month.',
                              },
                              {
                                title: 'Graph total rental minutes',
                                subtitle: 'for each month.',
                                value: 'Graph total rental minutes for each month.',
                              },
                            ],
                          }}
                        />
                      </div>
                    )}
                  </SpaceBetween>
                  {/* TODO better error viewing */}
                  {/* TODO would prefer more of a popup error as this takes up space */}
                  {/* TODO popup tost errors instead */}
                  {/* {error ? (
                <Alert header="Error" statusIconAriaLabel="Error" type="error">
                  {error}
                </Alert>
              ) : undefined} */}
                </div>
              )}
            </Grid>
          </div>
          <Grid gridDefinition={[{ colspan: { default: 12, m: 8 }, offset: { default: 0, m: 2 } }]}>
            <ChatBar addMessage={addMessage} error={error} loading={loading || awaiting} />
          </Grid>
        </div>
      }
      // TODO use notifications for errors
      // notifications
      headerSelector=".top-navigation"
      maxContentWidth={Number.MAX_VALUE}
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      disableBodyScroll
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}

type GetLoadingOrErrorProps = {
  loading: boolean;
  retry: () => void;
  error: string;
};

function GetLoadingOrError(props: GetLoadingOrErrorProps) {
  const { loading, retry, error } = props;

  return (
    <SpaceBetween direction="vertical" size="s">
      <Container className="chat-turn-assistant-text">
        <Box textAlign="center">
          {!error ? (
            <Box variant="h4">Loading...</Box>
          ) : (
            <Box variant="h4">
              <br />
            </Box>
          )}
          <Spinner size="large" variant={error ? 'inverted' : 'normal'} />
        </Box>
      </Container>
      {error ? (
        <Alert
          action={
            <Button disabled={loading} onClick={retry}>
              Retry
            </Button>
          }
          header="Error retrieving chat"
          statusIconAriaLabel="Error"
          type="error"
        >
          {error}
        </Alert>
      ) : undefined}
    </SpaceBetween>
  );
}

export function ChatHeader() {
  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 4, xxs: 3, xs: 2, m: 1 } },
        { colspan: { default: 8, xxs: 9, xs: 10, m: 11 } },
      ]}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <img
          alt="Zappy Logo"
          height="auto"
          src="https://needzappy.com/wp-content/uploads/2023/09/zappy-logoAsset-1.svg"
          style={{
            maxHeight: '100px',
            maxWidth: '100px',
          }}
          width="100%"
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Header variant="h1">
          <span
            style={{
              fontSize: '30px',
              color: '#d5de24',
            }}
          >
            Chat
          </span>
        </Header>
      </div>
    </Grid>
  );
}
