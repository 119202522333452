import { SpaceBetween, FormField, DatePicker, Select, Button } from '@cloudscape-design/components';
import { useState } from 'react';

export type DashboardFilterProps = {
  date: string;
  setDate: (date: string) => void;
  days: number;
  setDays: (days: number) => void;
  fetchApi: () => void;
  loading: boolean;
}

export function DashboardFilter(props: DashboardFilterProps) {
  const options = [
    { label: '7 Days', value: '7' },
    { label: '30 Days', value: '30' },
    { label: '60 Days', value: '60' },
    { label: '90 Days', value: '90' },
  ];

  const { date, setDate, days, setDays, fetchApi, loading } = props;
  const [localDate, setLocalDate] = useState(date);

  return (
    <SpaceBetween direction="horizontal" size="s">
      <FormField constraintText="Use YYYY/MM/DD format." label="Reporting Date">
        <DatePicker
          disabled={loading}
          locale="en-US"
          openCalendarAriaLabel={(selectedDate) =>
            'Choose reporting date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
          }
          placeholder="YYYY/MM/DD"
          value={localDate}
          expandToViewport
          onChange={({ detail }) => {
            const newDate = detail.value;
            setLocalDate(newDate);

            // Need to check that the date is actually in YYYY-MM-DD format
            if (newDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
              setDate(newDate);
            }
          }}
        />
      </FormField>
      <FormField
        constraintText="Number of days to look back."
        label={days === 1 ? `Last ${days} day` : `Last ${days} days`}
      >
        <Select
          disabled={loading}
          options={options}
          selectedOption={options.find((option) => option.value === days.toString()) || options[0]}
          expandToViewport
          onChange={({ detail }) =>
            setDays(Math.max(parseInt(detail.selectedOption.value ?? '30'), 1))
          }
        />
      </FormField>
      <FormField label="Refresh">
        <Button disabled={loading} iconName="refresh" onClick={fetchApi} />
      </FormField>
    </SpaceBetween>
  );
}
