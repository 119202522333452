export function formatFinancial(x: number | undefined, decimals = 2): string {
  if (x === undefined) {
    return '';
  }

  return '$' + x.toFixed(decimals).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

export function formatBig(x: number | undefined, decimals = 0): string {
  if (x === undefined) {
    return '';
  }
  // always two or three significant digits
  // 2 -> 2
  // 20 -> 20
  // 200 -> 200
  // 1200 -> 1.2k
  // 11000 -> 11k
  // 123000 -> 123k
  // 1230000 -> 1.2M

  if (x < 1000) {
    return x.toFixed(decimals);
  } else if (x < 1000000) {
    return (x / 1000).toFixed(decimals) + 'k';
  }

  return (x / 1000000).toFixed(decimals) + 'M';
}

export function truncateWithEllipsis(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text;
  }

  return text.slice(0, maxLength - 3) + '...';
}

export function getDateFormatter(data: Date[]) {
  // Calculate the minimum and maximum date
  const minDate = data.reduce((a, b) => (a < b ? a : b));
  const maxDate = data.reduce((a, b) => (a > b ? a : b));

  // Calculate the differences between consecutive dates
  const diffs = data.slice(1).map((d, i) => d.getTime() - data[i].getTime());
  const minDiff = diffs.length > 0 ? Math.min(...diffs) : 0;

  // Determine the range of dates to understand how to format
  const minuteInMillis = 1000 * 60;
  const hourInMillis = minuteInMillis * 60;
  const dayInMillis = hourInMillis * 24;
  const weekInMillis = dayInMillis * 7;
  const monthInMillis = dayInMillis * 30;

  const range = maxDate.getTime() - minDate.getTime();

  if (range < dayInMillis) {
    // All times are within a single day
    if (minDiff >= hourInMillis) {
      // Minimum diff is greater than or equal to an hour
      return (e: Date) =>
        e.toLocaleTimeString('en-US', {
          hour: '2-digit',
        });
    } else if (minDiff >= minuteInMillis) {
      // Minimum diff is greater than or equal to a minute
      return (e: Date) =>
        e.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        });
    }
 
      // Show seconds when the diff is less than a minute
      return (e: Date) =>
        e.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
    
  } else if (range < weekInMillis) {
    // Range spans across multiple days but less than a week
    return (e: Date) =>
      e.toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
  } else if (range < monthInMillis) {
    // Range spans across multiple weeks but less than a month
    return (e: Date) =>
      e.toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
      });
  }
 
    // Range spans across multiple months
    if (minDiff >= monthInMillis) {
      // If min diff is at least a month, show only year and month
      return (e: Date) =>
        e.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
        });
    } else if (minDiff >= weekInMillis) {
      // If min diff is at least a week but less than a month, show month and year
      return (e: Date) =>
        e.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
        });
    }
 
      // For smaller diffs, show day as well
      return (e: Date) =>
        e.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        });
    
  
}
