import {
  PropertyFilterOption,
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';
import { useEffect, useMemo, useState } from 'react';

import { Order } from '../common/types';
import { useApiNoBody } from '../common/api';
import {
  getFilterValues,
  getDevicesAndVenues,
  getDuration as getOrderDuration,
} from '../common/orders';
import {
  FilterConfig,
  RangeFilterFunction,
  createFilter,
  filterCurrency,
  filterDuration,
  filterText,
  filterTextMatch as filterTextExact,
  getDurationString,
  rangeStartEnd,
  useUrlFilterState,
  useUrlRangeState,
} from '../common/filter';
import { IGNORE_VENUES } from '../common/venue';

export const ORDER_FILTERS: PropertyFilterProperty[] = [
  {
    key: 'venue',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Venue',
    groupValuesLabel: 'Device venues',
  },
  {
    key: 'venue_type',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Venue Type',
    groupValuesLabel: 'Device venue types',
  },
  {
    key: 'payment_status',
    operators: ['=', '!='],
    propertyLabel: 'Payment Status',
    groupValuesLabel: 'Payment Statuses',
  },
  {
    key: 'rental_status',
    operators: ['=', '!='],
    propertyLabel: 'Rental Status',
    groupValuesLabel: 'Rental Statuses',
  },
  {
    key: 'device',
    operators: ['=', '!='],
    propertyLabel: 'Device',
    groupValuesLabel: 'Devices',
  },
  {
    key: 'source',
    operators: ['=', '!='],
    propertyLabel: 'Source',
    groupValuesLabel: 'Sources',
  },
  {
    key: 'revenue',
    operators: ['=', '!=', '>=', '<=', '>', '<'],
    propertyLabel: 'Revenue',
    groupValuesLabel: 'Revenue',
  },
  {
    key: 'duration',
    operators: ['=', '!=', '>=', '<=', '>', '<'],
    propertyLabel: 'Duration',
    groupValuesLabel: 'Duration',
  },
];

export const ORDER_FILTER_CONFIGS: FilterConfig<Order>[] = [
  {
    propertyKey: 'venue',
    func: (token, item) => filterText(token, item.from_venue.name),
  },
  {
    propertyKey: 'venue_type',
    func: (token, item) => filterText(token, item.from_venue.type ?? 'Uncategorized'),
  },
  {
    propertyKey: 'payment_status',
    func: (token, item) => filterTextExact(token, item.payment_status),
  },
  {
    propertyKey: 'rental_status',
    func: (token, item) => filterTextExact(token, item.rent_status),
  },
  {
    propertyKey: 'source',
    func: (token, item) => filterTextExact(token, item.order_type === 'Uber' ? 'Uber' : 'Kiosk'),
  },
  {
    propertyKey: 'device',
    func: (token, item) => filterTextExact(token, item.device.device_id),
  },
  {
    propertyKey: 'revenue',
    func: (token, item) => {
      const revenue = parseFloat(item.settlement_amount);

      return filterCurrency(token, revenue);
    },
  },
  {
    propertyKey: 'duration',
    func: (token, item) => {
      const duration = getOrderDuration(item) ?? -1;

      return filterDuration(token, duration);
    },
  },
];

export function useOrderState() {
  const [range, setRange] = useState<DateRangePickerProps.Value | null>({
    key: 'previous-1-month',
    amount: 1,
    unit: 'month',
    type: 'relative',
  });
  const [query, setQuery] = useState<PropertyFilterQuery>({
    operation: 'and',
    tokens: [],
  });

  return {
    range,
    setRange,
    query,
    setQuery,
  };
}

export function useOrderFilterState(
  apiItems: Order[],
  setApiItems: (items: Order[]) => void,
  range: DateRangePickerProps.Value | null,
  query: PropertyFilterQuery,
  setRange: (range: DateRangePickerProps.Value | null) => void,
  setQuery: (query: PropertyFilterQuery) => void,
  route?: string,
) {
  const filterValues = useMemo(() => getFilterValues(apiItems), [apiItems]);

  const propertyFilteringOptions: PropertyFilterOption[] = [];
  filterValues.venues.sort();

  for (const venue of filterValues.venues) {
    propertyFilteringOptions.push({
      propertyKey: 'venue',
      value: venue.name,
    });
  }

  filterValues.venueTypes.sort();

  for (const venueType of filterValues.venueTypes) {
    propertyFilteringOptions.push({
      propertyKey: 'venue_type',
      value: venueType,
    });
  }

  filterValues.devices.sort();

  for (const device of filterValues.devices) {
    propertyFilteringOptions.push({
      propertyKey: 'device',
      value: device.device_id,
    });
  }

  filterValues.payment_statuses.sort();

  for (const payment_status of filterValues.payment_statuses) {
    propertyFilteringOptions.push({
      propertyKey: 'payment_status',
      value: payment_status,
    });
  }

  filterValues.rental_statuses.sort();

  for (const rental_status of filterValues.rental_statuses) {
    propertyFilteringOptions.push({
      propertyKey: 'rental_status',
      value: rental_status,
    });
  }

  for (const source of filterValues.sources) {
    propertyFilteringOptions.push({
      propertyKey: 'source',
      value: source,
    });
  }

  propertyFilteringOptions.push({
    propertyKey: 'revenue',
    value: `$${filterValues.revenues.min.toFixed(2)} USD`,
  });
  propertyFilteringOptions.push({
    propertyKey: 'revenue',
    value: `$${filterValues.revenues.max.toFixed(2)} USD`,
  });

  propertyFilteringOptions.push({
    propertyKey: 'duration',
    value: getDurationString(filterValues.durations.max),
  });

  propertyFilteringOptions.push({
    propertyKey: 'duration',
    value: '0h 0m 0s',
  });

  propertyFilteringOptions.push({
    propertyKey: 'duration',
    value: 'N/A',
  });

  let apiPath = route ? route : '/orders-raw';
  // add query params to apiPath
  const params = new URLSearchParams();

  if (range) {
    const { start, end } = rangeStartEnd(range);
    // correct format and correct UTC time
    params.append('start', start.toISOString());
    params.append('end', end.toISOString());
  }

  // TODO add query to dependency array and as query params
  apiPath += `?${params.toString()}`;

  const [api, error, loading] = useApiNoBody<Order[]>(apiPath, 'GET', (orders) => {
    setApiItems(
      orders.filter((order) => {
        if (IGNORE_VENUES.includes(order.from_venue.name)) {
          return false;
        }

        return true;
      }),
    );
  });

  const fetchApi = async () => {
    await api();
  };

  const filterTable = createFilter<Order>(query, ORDER_FILTER_CONFIGS);

  const filteredItems = apiItems.filter(filterTable);

  return {
    fetchApi,
    range,
    setRange,
    query,
    setQuery,
    propertyFilteringOptions,
    filteredItems,
    loading,
    error,
  };
}

export function useOrders(route?: string) {
  const [apiItems, setApiItems] = useState<Order[]>([]);
  const { range, setRange, query, setQuery } = useOrderState();

  return useOrderFilterState(apiItems, setApiItems, range, query, setRange, setQuery, route);
}

export function useOrdersWithUrl(route?: string) {
  const [apiItems, setApiItems] = useState<Order[]>([]);
  const { query, setQuery } = useUrlFilterState();
  const { range, setRange } = useUrlRangeState();

  return useOrderFilterState(apiItems, setApiItems, range, query, setRange, setQuery, route);
}
