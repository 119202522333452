import { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import jwt from 'jwt-decode';

import { LocalTopNavigation, NavigationContext } from './common/navigation';
import { UserTokenInfo } from './common/types';
import UserContext from './common/user';
import Dashboard from './dashboard';
import Login from './login';
import SignupConfirmation from './signup/confirmation';
import PayoutsSetup from './payouts/setup';
import Logout from './logout';
import Invite from './manage/invite';
import UsersManage from './manage/users';
import Error from './error';
import Reset from './reset';
import ResetChange from './reset/change';
import AccountPage from './account';
import UpdateAccountPage from './account/update';
import {
  RequireSomePermissionPrefix,
  RequireAuth,
  RequireNoAuth,
  RequirePermissions,
} from './common/auth';
import UserManage from './manage/users/user';
import AuthDashboard from './manage/auth';
import RoleManage from './manage/auth/role';
import OrdersPage from './orders';
import Homepage from './home';
import ReportPage from './report';
import DevicesPage from './devices';
import DevicePage from './devices/device';
import VenuesPage from './venues';
import VenuePage from './venues/venue';
import Chat from './chat';
import History from './history';
import Access from './access';

import '@cloudscape-design/global-styles/index.css';
import './global.css';

function App() {
  const { innerWidth: width } = window;
  const [navigationOpen, setNavigationOpen] = useState(width > 688);
  const navigationSize = 200;
  const { pathname, hash, key, search } = useLocation();
  const token = localStorage.getItem('token');
  let jwtToken: UserTokenInfo | null = null;

  try {
    if (token) {
      const jwtRaw = jwt(token);
      // jwtToken = token ? ((jwt(token) instanceof UserTokenInfo) ? jwt(token) as UserTokenInfo : null) : null;
      jwtToken = jwtRaw as UserTokenInfo;

      if (jwtToken) {
        if (jwtToken.type !== 'access') {
          localStorage.removeItem('token');
          jwtToken = null;
        }
      }
    }
  } catch (e) {
    console.error(e);
  }

  if (!jwtToken) {
    localStorage.removeItem('token');
  }

  const [user, setUser] = useState<UserTokenInfo | null>(jwtToken);

  useEffect(() => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    } else if (!search) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <LocalTopNavigation />
      <NavigationContext.Provider value={{ navigationOpen, setNavigationOpen, navigationSize }}>
        <Routes>
          <Route element={<Error />} path="*" />
          <Route element={<Homepage />} path="/" />
          <Route path="/account">
            <Route
              element={
                <RequireAuth>
                  <AccountPage />
                </RequireAuth>
              }
              index
            />
            <Route
              element={
                <RequireAuth>
                  <UpdateAccountPage />
                </RequireAuth>
              }
              path="update"
            />
          </Route>
          <Route
            element={
              <RequireNoAuth>
                <Login />
              </RequireNoAuth>
            }
            path="/login"
          />
          <Route path="/signup">
            <Route
              element={
                <RequireNoAuth>
                  <SignupConfirmation />
                </RequireNoAuth>
              }
              path="confirmation"
            />
          </Route>
          <Route path="/payouts">
            <Route element={<PayoutsSetup />} path="setup" />
          </Route>
          <Route
            element={
              <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                <Dashboard />
              </RequireSomePermissionPrefix>
            }
            path="/dashboard"
          />
          <Route path="/orders">
            <Route
              element={
                <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                  <OrdersPage />
                </RequireSomePermissionPrefix>
              }
              index
            />
          </Route>
          <Route path="/devices">
            <Route
              element={
                <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                  <DevicesPage />
                </RequireSomePermissionPrefix>
              }
              index
            />
            <Route
              element={
                <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                  <DevicePage />
                </RequireSomePermissionPrefix>
              }
              path=":deviceId"
            />
          </Route>
          <Route path="/venues">
            <Route
              element={
                <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                  <VenuesPage />
                </RequireSomePermissionPrefix>
              }
              index
            />
            <Route
              element={
                <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                  <VenuePage />
                </RequireSomePermissionPrefix>
              }
              path=":venueId"
            />
          </Route>
          <Route path="/report">
            <Route
              element={
                <RequireSomePermissionPrefix permissions={['orders.get', 'orders.venue.']}>
                  <ReportPage />
                </RequireSomePermissionPrefix>
              }
              index
            />
          </Route>
          <Route element={<Logout />} path="/logout" />
          <Route path="/reset">
            <Route element={<Reset />} index />
            <Route element={<ResetChange />} path="change" />
          </Route>
          <Route
            element={
              <RequirePermissions permissions={['orders.get']}>
                <Chat />
              </RequirePermissions>
            }
            path="/chat/:chatId?"
          />
          <Route element={<Access />} path="/access/:code" />
          <Route
            element={
              <RequirePermissions permissions={['orders.get']}>
                <History />
              </RequirePermissions>
            }
            path="/history"
          />
          <Route path="manage">
            <Route
              element={
                <RequirePermissions
                  permissions={['invites.create', 'invites.get', 'invites.delete']}
                >
                  <Invite />
                </RequirePermissions>
              }
              path="invites"
            />
            <Route path="users">
              <Route
                element={
                  <RequirePermissions permissions={['users.get', 'users.roles.get']}>
                    <UsersManage />
                  </RequirePermissions>
                }
                index
              />
              <Route
                element={
                  <RequirePermissions
                    permissions={[
                      'users.get',
                      'users.roles.add',
                      'users.roles.get',
                      'users.roles.delete',
                    ]}
                  >
                    <UserManage />
                  </RequirePermissions>
                }
                path=":userId"
              />
            </Route>
            <Route path="auth">
              <Route
                element={
                  // TODO add permissions
                  <RequirePermissions permissions={['roles.create', 'permissions.create']}>
                    <AuthDashboard />
                  </RequirePermissions>
                }
                index
              />
              <Route
                element={
                  <RequirePermissions permissions={['roles.create', 'permissions.create']}>
                    <RoleManage />
                  </RequirePermissions>
                }
                path="roles/:roleId"
              />
              {/* TODO additional auth views here */}
            </Route>
          </Route>
        </Routes>
      </NavigationContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
