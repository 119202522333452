import { createContext, useContext } from 'react';
import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import BreadcrumbGroup, {
  BreadcrumbGroupProps,
} from '@cloudscape-design/components/breadcrumb-group';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import { useLocation, useNavigate } from 'react-router-dom';

import UserContext, { hasPermissionPrefix, hasPermissions } from '../user';
import { useAxiosInterceptors } from '../api/axiosInstance';

export type NavigationContextType = {
  navigationOpen: boolean;
  setNavigationOpen: (value: boolean) => void;
  navigationSize: number;
};

export const NavigationContext = createContext<NavigationContextType>({
  navigationOpen: true,
  navigationSize: 200,
  setNavigationOpen: (_value: boolean) => {
    return;
  },
});

const HOME = { text: 'Zappy', href: '/' };

export function Breadcrumbs({ items }: { items: BreadcrumbGroupProps.Item[] }) {
  const navigate = useNavigate();

  return (
    <BreadcrumbGroup
      items={[HOME, ...items]}
      onClick={(event) => {
        if (event.detail.external) {
          return;
        }
        event.preventDefault();
        navigate(event.detail.href);
      }}
    />
  );
}

export function LocalNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);

  const sideItems: SideNavigationProps.Item[] = [];

  const orderItems: SideNavigationProps.Item[] = [];

  if (hasPermissions(user, ['orders.get']) || hasPermissionPrefix(user, 'orders.venue.')) {
    orderItems.push({ type: 'link', text: 'Dashboard', href: '/dashboard' });
    orderItems.push({ type: 'link', text: 'Reports', href: '/report' });
    orderItems.push({ type: 'link', text: 'Rentals', href: '/orders' });
  }

  if (orderItems.length > 0) {
    if (sideItems.length > 0) {
      sideItems.push({ type: 'divider' });
    }
    sideItems.push({
      type: 'section',
      text: 'Analytics',
      items: orderItems,
    });
  }

  if (hasPermissions(user, ['orders.get'])) {
    const chatItems: SideNavigationProps.Item[] = [];

    if (sideItems.length > 0) {
      sideItems.push({ type: 'divider' });
    }
    chatItems.push({ type: 'link', text: 'Chat', href: '/chat' });
    chatItems.push({ type: 'link', text: 'History', href: '/history' });

    sideItems.push({
      type: 'section',
      text: 'Chat',
      items: chatItems,
    });
  }

  const deviceItems: SideNavigationProps.Item[] = [];

  if (hasPermissions(user, ['orders.get']) || hasPermissionPrefix(user, 'orders.venue.')) {
    deviceItems.push({ type: 'link', text: 'Venues', href: '/venues' });
    deviceItems.push({ type: 'link', text: 'Devices', href: '/devices' });
  }

  if (deviceItems.length > 0) {
    if (sideItems.length > 0) {
      sideItems.push({ type: 'divider' });
    }
    sideItems.push({
      type: 'section',
      text: 'Maintenance',
      items: deviceItems,
    });
  }

  const manageItems: SideNavigationProps.Item[] = [];

  if (
    hasPermissions(user, ['users.get', 'users.roles.add', 'users.roles.get', 'users.roles.delete'])
  ) {
    manageItems.push({ type: 'link', text: 'Users', href: '/manage/users' });
  }

  if (hasPermissions(user, ['invites.create', 'invites.get', 'invites.delete'])) {
    manageItems.push({ type: 'link', text: 'Invites', href: '/manage/invites' });
  }

  if (hasPermissions(user, ['roles.create', 'permissions.create'])) {
    manageItems.push({ type: 'link', text: 'Auth', href: '/manage/auth' });
  }

  if (manageItems.length > 0) {
    sideItems.push({ type: 'divider' });
    sideItems.push({
      type: 'section',
      text: 'Manage',
      items: manageItems,
    });
  }

  return (
    <SideNavigation
      activeHref={location.pathname}
      className="side-navigation"
      items={sideItems}
      onFollow={(event) => {
        if (event.detail.external) {
          return;
        }
        event.preventDefault();
        navigate(event.detail.href);
      }}
    />
  );
}

export function LocalTopNavigation() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  useAxiosInterceptors();

  return (
    <TopNavigation
      className="top-navigation"
      identity={{
        title: 'Zappy',
        href: '/',
        logo: {
          src: 'https://needzappy.com/wp-content/uploads/2023/08/markAsset-7@2x.png',
          alt: 'Zappy',
        },
        onFollow: (event) => {
          event.preventDefault();
          navigate('/');
        },
      }}
      utilities={
        user
          ? // Check if the user has an email address
            // if not, they are a temporary user
            user.email.includes('@')
            ? [
                {
                  type: 'menu-dropdown',
                  text: user.email,
                  iconName: 'user-profile',
                  onItemClick: (event) => {
                    if (event.detail.external) {
                      return;
                    }
                    event.preventDefault();
                    const href = event.detail.href;

                    if (href) {
                      navigate(href);
                    }
                  },
                  items: [
                    { id: 'account', text: 'Account', href: '/account' },
                    { id: 'signout', text: 'Sign out', href: '/logout' },
                  ],
                },
              ]
            : [
                {
                  type: 'button',
                  text: 'Sign out',
                  href: '/logout',
                  onClick: (event) => {
                    event.preventDefault();
                    navigate('/logout');
                  },
                },
              ]
          : [
              {
                type: 'button',
                text: 'Login',
                href: '/login',
                onClick: (event) => {
                  event.preventDefault();
                  navigate('/login');
                },
              },
            ]
      }
    />
  );
}
