import { useContext, useEffect, useState } from 'react';
import {
  AppLayout,
  Box,
  Button,
  Container,
  ContentLayout,
  Grid,
  Header,
  Modal,
  SpaceBetween,
  Spinner,
  StatusIndicator,
} from '@cloudscape-design/components';
import { useNavigate, useParams } from 'react-router-dom';
import jwt from 'jwt-decode';

import { UserTokenInfo, UserResponse } from '../common/types';
import UserContext from '../common/user';
import { useApiNoBody } from '../common/api';

export default function Access() {
  const { setUser } = useContext(UserContext);

  const params = useParams();

  // Chat UUID
  const [code] = useState(params.code);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [accessApi, error, loading] = useApiNoBody<UserResponse>(
    `/auth/access/${code}`,
    'POST',
    (response) => {
      const token = response.token;
      const refresh_token = response.refresh_token;
      localStorage.setItem('token', token);
      localStorage.setItem('refresh_token', refresh_token);
      setUser(token ? (jwt(token) as UserTokenInfo) : null);
      setOpen(true);
    },
  );

  const access = async () => {
    await accessApi();
  };

  useEffect(() => {
    access();
  }, []);

  return (
    <AppLayout
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Temporary Access to Zappy Analytics</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 8,
                  s: 6,
                },
              },
            ]}
          >
            <Container header={<Header variant="h2">Temporary Access</Header>}>
              {loading ? (
                <StatusIndicator type="loading">Accessing...</StatusIndicator>
              ) : error ? (
                <StatusIndicator type="error">{error}</StatusIndicator>
              ) : (
                <StatusIndicator type="success">Access granted</StatusIndicator>
              )}
              <Modal
                closeAriaLabel="Close"
                footer={
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        variant="primary"
                        onClick={() => {
                          navigate('/');
                        }}
                      >
                        Continue
                      </Button>
                    </SpaceBetween>
                  </Box>
                }
                header={
                  <Header description="Welcome to Zappy Analytics!" variant="h2">
                    Temporary Access Granted
                  </Header>
                }
                visible={open}
                onDismiss={() => {
                  navigate('/');
                }}
              >
                <SpaceBetween size="xs">
                  <Box variant="p">
                    Welcome! You have been granted temporary access to Zappy Analytics. You can now
                    access our platform and view real-time Zappy data. Be sure to check out:
                  </Box>
                  <Container
                    header={
                      <Header
                        actions={
                          <Button
                            variant="normal"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate('/dashboard');
                            }}
                          >
                            View
                          </Button>
                        }
                        description="Real-time customized reporting"
                        variant="h3"
                      >
                        Zappy Reporting
                      </Header>
                    }
                  >
                    <Box variant="p">
                      Create real-time customized reports. You can create reports on the fly, view
                      them in real-time, and share them with others.
                    </Box>
                  </Container>
                  <Container
                    header={
                      <Header
                        actions={
                          <Button
                            variant="normal"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate('/chat');
                            }}
                          >
                            View
                          </Button>
                        }
                        description="Have a conversation with our data!"
                        variant="h3"
                      >
                        Zappy Chat
                      </Header>
                    }
                  >
                    <Box variant="p">
                      An AI-powered chatbot for customized graphing, reporting, and more. Ask Zappy
                      Chat anything you are curious about!
                    </Box>
                  </Container>
                  <Container
                    header={
                      <Header
                        actions={
                          <Button
                            variant="normal"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate('/devices');
                            }}
                          >
                            View
                          </Button>
                        }
                        description="Monitor the status of Zappy devices and venues"
                        variant="h3"
                      >
                        Zappy Maintenance
                      </Header>
                    }
                  >
                    <Box variant="p">
                      Monitor the status of Zappy devices and venues. View the uptime of devices,
                      see the orders for each device and venue, and much more!
                    </Box>
                  </Container>
                  <Box variant="p">
                    Your access through this link will expire after one week, after which you will
                    need a new link. Enjoy your time with Zappy Analytics!
                  </Box>
                </SpaceBetween>
              </Modal>
            </Container>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigationHide
      toolsHide
    />
  );
}
