import {
  PropertyFilterOption,
  PropertyFilterQuery,
  useCollection,
} from '@cloudscape-design/collection-hooks';
import {
  Box,
  Header,
  SpaceBetween,
  Button,
  Table,
  Pagination,
  PropertyFilter,
  DateRangePicker,
  DateRangePickerProps,
  PropertyFilterProps,
} from '@cloudscape-design/components';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Order, OrderMeta } from '../common/types';
import { getHeaderCounterText } from '../common/full-header';
import { PaymentStatusIcon, RentalStatusIcon, getDuration } from '../common/orders';
import { checkIsValidRange } from '../common/range';
import {
  getDurationString,
  useLocalFilterState,
  useLocalPageState,
  useLocalRangeState,
  useUrlFilterState,
  useUrlPageState,
  useUrlRangeState,
} from '../common/filter';
import { VenueLink } from '../venues/link';
import { DeviceLink } from '../devices/link';
import { exportExcel } from '../common/excel';
import { getOrders, getOrdersMeta } from '../common/api/orders';
import { buildFiltering } from '../common/filters/orders';
import { ResourceError } from '../common/loading';
import { PaginatedResponse } from '../common/api/query';

export type OrderFilterProps = {
  query: PropertyFilterQuery;
  setQuery: (query: PropertyFilterQuery) => void;
  options: PropertyFilterOption[];
  properties: PropertyFilterProps.FilteringProperty[];
  loading: boolean;
  range: DateRangePickerProps.Value | null;
  setRange: (range: DateRangePickerProps.Value | null) => void;
  skipColumns?: string[];
  hideFilter?: boolean;
  hideRange?: boolean;
};

export function OrderFilter(props: OrderFilterProps) {
  const { query, setQuery, options, properties, loading, range, setRange } = props;

  const filteredOptions = useMemo(() => {
    return options.filter((option) => !props.skipColumns?.includes(option.propertyKey));
  }, [options, props.skipColumns]);

  const filteredProperties = useMemo(() => {
    return properties.filter((property) => !props.skipColumns?.includes(property.key));
  }, [properties, props.skipColumns]);

  return (
    <SpaceBetween direction="horizontal" size="s">
      {!props.hideFilter && (
        <PropertyFilter
          disabled={loading}
          filteringOptions={filteredOptions}
          filteringProperties={filteredProperties}
          query={query}
          onChange={({ detail }) => setQuery(detail)}
        />
      )}
      {!props.hideRange && (
        <DateRangePicker
          disabled={loading}
          isValidRange={checkIsValidRange}
          placeholder="Filter by a date and time range"
          relativeOptions={[
            {
              key: 'previous-12-hours',
              amount: 12,
              unit: 'hour',
              type: 'relative',
            },
            {
              key: 'previous-1-day',
              amount: 1,
              unit: 'day',
              type: 'relative',
            },
            {
              key: 'previous-1-week',
              amount: 1,
              unit: 'week',
              type: 'relative',
            },
            {
              key: 'previous-1-month',
              amount: 1,
              unit: 'month',
              type: 'relative',
            },
            {
              key: 'previous-1-year',
              amount: 1,
              unit: 'year',
              type: 'relative',
            },
          ]}
          value={range}
          onChange={({ detail }) => setRange(detail.value)}
        />
      )}
    </SpaceBetween>
  );
}

export function UrlOrderTable() {
  const { query, setQuery } = useUrlFilterState();
  const { range, setRange } = useUrlRangeState();
  const [page, setPage] = useUrlPageState();

  return (
    <ProvidedOrderTable
      page={page}
      query={query}
      range={range}
      setPage={setPage}
      setQuery={setQuery}
      setRange={setRange}
      fullPage
    />
  );
}

export function LocalOrderTable() {
  const { query, setQuery } = useLocalFilterState();
  const { range, setRange } = useLocalRangeState();
  const [page, setPage] = useLocalPageState();

  return (
    <ProvidedOrderTable
      page={page}
      query={query}
      range={range}
      setPage={setPage}
      setQuery={setQuery}
      setRange={setRange}
    />
  );
}

export type ProvidedOrderTableProps = {
  query: PropertyFilterQuery;
  setQuery: (query: PropertyFilterQuery) => void;
  range: DateRangePickerProps.Value | null;
  setRange: (range: DateRangePickerProps.Value | null) => void;
  page: number;
  setPage: (page: number) => void;
  fullPage?: boolean;
  description?: ReactNode;
  hideRange?: boolean;
  hideFilter?: boolean;
  getOrdersQuery?: (
    query: PropertyFilterQuery,
    page: number,
    pageSize: number,
    range: DateRangePickerProps.Value | null,
    now: Date,
  ) => Promise<PaginatedResponse<Order>>;
  getMetaQuery?: () => Promise<OrderMeta>;
  filter?: ReactNode;
  key?: string;
  skipColumns?: string[];
  pageSize?: number;
};

export function ProvidedOrderTable(props: ProvidedOrderTableProps) {
  const { query, setQuery, range, setRange, page, setPage, getOrdersQuery, getMetaQuery, key } =
    props;

  const endpointName = 'orders';
  const pageSize = props.pageSize ?? 50;
  const [pageCount, setPageCount] = useState(1);
  const metaQuery = useQuery({
    queryKey: [key, endpointName, 'meta'],
    queryFn: getMetaQuery ? getMetaQuery : getOrdersMeta,
  });
  const { options, properties } = useMemo(() => {
    if (metaQuery.data) {
      return buildFiltering(metaQuery.data);
    }

    return {
      options: [],
      properties: [],
    };
  }, [metaQuery.data]);

  const [now, setNow] = useState(new Date());

  const { isPending, isFetching, isLoading, error, data, refetch } = useQuery({
    queryKey: [key, endpointName, query, range, pageSize, page, now],
    enabled: metaQuery.isSuccess,
    // TODO add sorting
    queryFn: async () => {
      if (getOrdersQuery) {
        return await getOrdersQuery(query, page, pageSize, range, now);
      }

      return await getOrders(query, page, pageSize, range, now);
    },
    staleTime: 60_000, // 60 seconds
    // placeholderData: keepPreviousData,
  });
  const loading = isFetching || isLoading || isPending;

  useEffect(() => {
    if (data && data.pages !== pageCount) {
      setPageCount(data.pages);

      if (page > data.pages) {
        setPage(1);
      }
    }
  }, [data, pageCount, page]);

  const pagination = (
    <Pagination
      currentPageIndex={page}
      disabled={loading}
      pagesCount={pageCount}
      onChange={({ detail }) => setPage(detail.currentPageIndex)}
    />
  );

  const itemFilter =
    (!props.hideFilter || !props.hideRange) &&
    (props.filter ? (
      props.filter
    ) : (
      <OrderFilter
        hideFilter={props.hideFilter}
        hideRange={props.hideRange}
        loading={loading}
        options={options}
        properties={properties}
        query={query}
        range={range}
        setQuery={setQuery}
        setRange={setRange}
        skipColumns={props.skipColumns}
      />
    ));

  return (
    <OrderTable
      description={props.description}
      error={error}
      filter={itemFilter}
      fullPage={props.fullPage}
      items={data?.items ?? []}
      itemsTotal={data?.total}
      loading={loading}
      metaTotal={metaQuery.data?.total}
      pagination={pagination}
      refresh={() => {
        setNow(new Date());
        // refetch(); No need to refetch, the query will automatically refetch with the new `now` value
      }}
      skipColumns={props.skipColumns}
    />
  );
}

export type OrderTableProps = {
  refresh: () => void;
  items: Order[];
  loading: boolean;
  error: Error | null;
  filter?: ReactNode;
  pagination?: ReactNode;
  actions?: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
  skipColumns?: string[];
  itemsTotal?: number;
  metaTotal?: number;
  fullPage?: boolean;
};

export function OrderTable(props: OrderTableProps) {
  const resourceName = 'Rental';

  const { refresh, items, loading, error, itemsTotal, metaTotal } = props;

  return (
    <Table
      columnDefinitions={[
        {
          id: 'rented',
          header: 'Rented',
          cell: (e: Order) => new Date(e.rent_time + 'Z').toLocaleString(),
        },
        // {
        //   id: 'payment_status',
        //   header: 'Payment Status',
        //   cell: (e: Order) => <PaymentStatusIcon status={e.payment_status} />,
        // },
        {
          id: 'rent_status',
          header: 'Status',
          cell: (e: Order) => <RentalStatusIcon status={e.rent_status} />,
        },
        {
          id: 'device_id',
          header: 'Device',
          cell: (e: Order) => <DeviceLink device={e.device} />,
        },
        {
          id: 'name',
          header: 'Venue',
          cell: (e: Order) => <VenueLink maxLength={20} venue={e.from_venue} />,
        },
        {
          id: 'revenue',
          header: 'Revenue',
          cell: (e: Order) => `$${parseFloat(e.settlement_amount).toFixed(2)} USD`,
        },
        {
          id: 'duration',
          header: 'Duration',
          cell: (e: Order) => {
            const diff = getDuration(e);

            return getDurationString(diff);
          },
        },
      ].filter((column) => !props.skipColumns?.includes(column.id))}
      empty={
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <ResourceError error={error} refetch={refresh} resourceName={resourceName} />
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      }
      filter={props.filter}
      header={
        <Header
          actions={
            props.actions ?? (
              <SpaceBetween direction="horizontal" size="s">
                <Button disabled={loading} iconName="refresh" onClick={refresh} />
                <Button
                  disabled={loading}
                  iconName="download"
                  onClick={() => {
                    exportExcel(
                      items.map((order) => ({
                        id: order.source_id,
                        order_no: order.order_number,
                        pos: order.order_type,
                        device_type: order.device.slots,
                        device_id: order.device.device_id,
                        slot: order.slot,
                        rent_time: new Date(order.rent_time + 'Z').toLocaleString(),
                        payment_status: order.payment_status,
                        rent_status: order.rent_status,
                        returned_time: order.returned_time
                          ? new Date(order.returned_time + 'Z').toLocaleString()
                          : '',
                        settlement_amount: order.settlement_amount,
                        from_store: order.from_venue.name,
                        return_store: order.return_venue ? order.return_venue.name : '',
                      })),
                      'orders',
                    );
                  }}
                />
              </SpaceBetween>
            )
          }
          counter={
            itemsTotal && metaTotal
              ? `(${itemsTotal.toLocaleString()}/${metaTotal.toLocaleString()})`
              : undefined
          }
          description={props.description}
          variant={props.fullPage ? 'awsui-h1-sticky' : undefined}
        >
          {props.title ?? `${resourceName}s`}
        </Header>
      }
      items={items}
      loading={loading}
      loadingText={`Loading ${resourceName.toLowerCase()}s`}
      pagination={props.pagination}
      stickyHeader={props.fullPage}
      trackBy="id"
      variant={props.fullPage ? 'full-page' : undefined}
    />
  );
}
