import { Button, Header, HeaderProps, SpaceBetween } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { InfoLink } from './info-link';
import { ChatSession } from './chat/types';

export function getHeaderCounterText(
  items: ReadonlyArray<unknown>,
  selectedItems: ReadonlyArray<unknown> | undefined,
) {
  return selectedItems && selectedItems?.length > 0
    ? `(${selectedItems.length}/${items.length})`
    : `(${items.length})`;
}

export type FullPageHeaderProps = {
  title?: string;
  createButtonText?: string;
  extraActions?: React.ReactNode;
  selectedItems: readonly ChatSession[];
  onInfoLinkClick?: () => void;
  onCopyClick?: () => void;
  onDeleteClick?: () => void;
} & HeaderProps;

export function FullPageHeader({
  title = 'Zappy Chats',
  createButtonText = 'Create chat',
  onInfoLinkClick,
  ...props
}: FullPageHeaderProps) {
  const isOnlyOneSelected = props.selectedItems.length === 1;
  const navigate = useNavigate();

  return (
    <Header
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            data-testid="header-btn-resume"
            disabled={!isOnlyOneSelected}
            onClick={() => {
              navigate(`/chat/${props.selectedItems[0].uuid}`);
            }}
          >
            Resume
          </Button>

          {/* 
            TODO - Implement copy
            <Button data-testid="header-btn-copy" disabled={!isOnlyOneSelected} onClick={props.onCopyClick}>
              Copy
            </Button> */}
          <Button
            data-testid="header-btn-delete"
            disabled={props.selectedItems.length === 0}
            onClick={props.onDeleteClick}
          >
            Delete
          </Button>
          <Button
            data-testid="header-btn-create"
            variant="primary"
            onClick={() => {
              navigate(`/chat`);
            }}
          >
            {createButtonText}
          </Button>
        </SpaceBetween>
      }
      info={
        onInfoLinkClick && (
          <InfoLink ariaLabel={`Information about ${title}.`} onFollow={onInfoLinkClick} />
        )
      }
      variant="awsui-h1-sticky"
      {...props}
    >
      {title}
    </Header>
  );
}
