import { useState } from 'react';

import { useApiNoBody } from '../common/api';

import { DeviceWithVenue } from './types';

export function useDevice(deviceId: string) {
  const [device, setDevice] = useState<DeviceWithVenue | null>(null);
  const [api, error, loading] = useApiNoBody<DeviceWithVenue>(
    `/devices/${deviceId}`,
    'GET',
    (item) => {
      setDevice({
        ...item,
        total_slots: item.slots,
        full_slots: item.vendor.name === 'Relink' ? undefined : item.slots - item.active_slots,
      });
    },
  );

  const fetchApi = async () => {
    await api();
  };

  return {
    device,
    fetchApi,
    loading,
    error,
  };
}
