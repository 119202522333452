import {
  PropertyFilterOption,
  PropertyFilterQuery,
  useCollection,
} from '@cloudscape-design/collection-hooks';
import {
  Box,
  Header,
  SpaceBetween,
  Button,
  Table,
  Pagination,
  PropertyFilter,
} from '@cloudscape-design/components';
import { ReactNode, useEffect } from 'react';

import { VenueWithDevices } from '../common/types';
import { getHeaderCounterText } from '../common/full-header';
import { PercentStatus } from '../common/percent-status';

import { VENUE_FILTERS, useVenuesWithUrl } from './venue-filtering';
import { VenueLink } from './link';

export type VenueFilterProps = {
  query: PropertyFilterQuery;
  setQuery: (query: PropertyFilterQuery) => void;
  propertyFilteringOptions: PropertyFilterOption[];
  loading: boolean;
};

export function VenueFilter(props: VenueFilterProps) {
  const { query, setQuery, propertyFilteringOptions, loading } = props;

  return (
    <PropertyFilter
      disabled={loading}
      filteringOptions={propertyFilteringOptions}
      filteringProperties={VENUE_FILTERS}
      query={query}
      onChange={({ detail }) => setQuery(detail)}
    />
  );
}

export function StandaloneVenueTable() {
  const { fetchApi, query, setQuery, propertyFilteringOptions, filteredItems, loading, error } =
    useVenuesWithUrl();

  useEffect(() => {
    fetchApi();
  }, []);

  const itemFilter = (
    <VenueFilter
      loading={loading}
      propertyFilteringOptions={propertyFilteringOptions}
      query={query}
      setQuery={setQuery}
    />
  );

  return (
    <VenueTable
      error={error}
      fetchApi={fetchApi}
      filteredItems={filteredItems}
      itemFilter={itemFilter}
      loading={loading}
      pageSize={15}
      fullPage
    />
  );
}

export type VenueTableProps = {
  fetchApi: () => void;
  filteredItems: VenueWithDevices[];
  loading: boolean;
  error: string | null;
  itemFilter?: ReactNode;
  actions?: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
  pageSize?: number;
  fullPage?: boolean;
};

export function VenueTable(props: VenueTableProps) {
  const pageSize = props.pageSize ?? 10;
  const resourceName = 'Venue';

  const { fetchApi, filteredItems, loading, error } = props;

  const { items, collectionProps, paginationProps } = useCollection(filteredItems, {
    pagination: { pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'online',
          sortingComparator(a, b) {
            return (
              ((a.devices.length === 0 ? -2 : a.online) ?? -1) -
              ((b.devices.length === 0 ? -2 : b.online) ?? -1)
            );
          },
        },
        isDescending: true,
      },
    },
  });

  return (
    <Table
      stickyHeader={props.fullPage}
      variant={props.fullPage ? 'full-page' : undefined}
      {...collectionProps}
      {...paginationProps}
      columnDefinitions={[
        {
          id: 'venue',
          header: 'Venue',
          cell: (e) => <VenueLink venue={e} />,
          sortingField: 'venue',
          sortingComparator(a, b) {
            return a.name.localeCompare(b.name);
          },
        },
        {
          id: 'type',
          header: 'Type',
          cell: (e) => e.type ?? 'Uncategorized',
          sortingField: 'type',
          sortingComparator(a, b) {
            const x = a.type ?? 'Uncategorized';
            const y = b.type ?? 'Uncategorized';

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'online',
          header: 'Devices Online',
          cell: (e) => <PercentStatus total={e.devices.length} value={e.online} />,
          sortingField: 'online',
          sortingComparator(a, b) {
            return (
              ((a.devices.length === 0 ? -2 : a.online) ?? -1) -
              ((b.devices.length === 0 ? -2 : b.online) ?? -1)
            );
          },
        },
        {
          id: 'full_slots',
          header: 'Full Slots',
          cell: (e) => (
            <PercentStatus
              max_percent={50}
              min_percent={10}
              total={e.total_slots}
              value={e.full_slots}
            />
          ),
          sortingField: 'full_slots',
          sortingComparator(a, b) {
            return (
              ((a.total_slots === 0 ? -2 : a.full_slots) ?? -1) -
              ((b.total_slots === 0 ? -2 : b.full_slots) ?? -1)
            );
          },
        },
      ]}
      empty={
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {error}
                </Box>
              </div>
              <Button onClick={fetchApi}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      }
      filter={props.itemFilter}
      header={
        <Header
          actions={
            props.actions ?? <Button disabled={loading} iconName="refresh" onClick={fetchApi} />
          }
          counter={getHeaderCounterText(filteredItems, collectionProps.selectedItems)}
          description={props.description}
          variant={props.fullPage ? 'awsui-h1-sticky' : undefined}
        >
          {props.title ?? `${resourceName}s`}
        </Header>
      }
      items={items}
      loading={loading}
      loadingText={`Loading ${resourceName.toLowerCase()}s`}
      pagination={<Pagination {...paginationProps} disabled={loading} />}
      trackBy="id"
    />
  );
}
