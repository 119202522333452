import { Grid, Header } from '@cloudscape-design/components';

export type ZappyHeaderProps = {
  title: string;
}

export function ZappyHeader(props: ZappyHeaderProps) {
  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 4, xxs: 3, xs: 2, m: 1 } },
        { colspan: { default: 8, xxs: 9, xs: 10, m: 11 } },
      ]}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <img
          alt="Zappy Logo"
          height="auto"
          src="https://needzappy.com/wp-content/uploads/2023/09/zappy-logoAsset-1.svg"
          style={{
            maxHeight: '100px',
            maxWidth: '100px',
          }}
          width="100%"
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Header
          // actions={
          // <Button
          //   variant="primary"
          //   onClick={() => {
          //     navigate('/report');
          //   }}
          // >
          //   Explore
          // </Button>
          // }
          variant="h1"
        >
          <span
            style={{
              fontSize: '30px',
              color: '#d5de24',
            }}
          >
            {props.title}
          </span>
        </Header>
      </div>
    </Grid>
  );
}
