import { useEffect, useState } from 'react';
import {
  Container,
  SpaceBetween,
  Box,
  Link,
  Spinner,
  Alert,
  StatusIndicator,
  ContentLayout,
  Header,
  AppLayout,
  Grid,
  Button,
} from '@cloudscape-design/components';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AccountSetupRequest, AccountSetupResponse } from '../../common/types';
import { useApi } from '../../common/api';

export default function Setup() {
  const navigate = useNavigate();

  const [queryParameters] = useSearchParams();

  const email = queryParameters.get('email') ?? undefined;
  const code = queryParameters.get('code') ?? undefined;

  const [loading, setLoading] = useState(true);
  const [loginUrl, setLoginUrl] = useState('');
  const [setupUrl, setSetupUrl] = useState('');
  const [currency, setCurrency] = useState('');

  const stripeServicesAgreementUrl =
    currency === 'USD'
      ? 'https://stripe.com/en-us/legal/ssa'
      : currency === 'CAD'
      ? 'https://stripe.com/en-ca/legal/ssa'
      : undefined;

  const setup = async () => {
    if (!email || !code) {
      return;
    }

    const info: AccountSetupRequest = {
      email,
      code,
    };

    await setupApi(info);
  };

  useEffect(() => {
    if (!email || !code) {
      navigate('/');
    }
    setup();
  }, [email, code]);

  const [setupApi, error] = useApi<AccountSetupRequest, AccountSetupResponse>(
    '/payouts/setup',
    'POST',
    (response) => {
      const setup_url = response.setup_url;

      if (response.currency) {
        setCurrency(response.currency);
      }

      if (setup_url) {
        setSetupUrl(setup_url);
      } else {
        if (response.login_url) {
          setLoginUrl(response.login_url);
        }
      }
      setLoading(false);
    },
  );

  return (
    <AppLayout
      className="app-layout"
      content={
        <ContentLayout
          header={<Header variant="h1">Setup Zappy Payouts</Header>}
          headerVariant="high-contrast"
        >
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 6,
                  s: 6,
                },
                offset: {
                  default: 0,
                  l: 3,
                  s: 3,
                },
              },
            ]}
          >
            <SpaceBetween direction="vertical" size="s">
              <Container header={<Header variant="h2">Payouts Setup</Header>}>
                <SpaceBetween direction="vertical" size="m">
                  {/* TODO: Add a status indicator here and show error if currency is not supported */}
                  {loading || !stripeServicesAgreementUrl ? (
                    <Box variant="p">Checking your payouts setup status...</Box>
                  ) : loginUrl ? (
                    <SpaceBetween direction="vertical" size="m">
                      <Box variant="p">
                        Your Zappy payouts account has been successfully set up!
                      </Box>
                      <Box variant="p">
                        Manage your account on Stripe and view your payouts dashboard:{' '}
                        <Link href={loginUrl} target="_blank" external>
                          Stripe Dashboard
                        </Link>
                        .
                      </Box>
                      <Box variant="p">
                        By registering your account, you agree to our Services Agreement and the{' '}
                        <Link href={stripeServicesAgreementUrl} target="_blank" external>
                          Stripe Services Agreement
                        </Link>
                        .
                      </Box>
                    </SpaceBetween>
                  ) : (
                    <SpaceBetween direction="vertical" size="m">
                      <Box variant="p">
                        By registering your account, you agree to our Services Agreement and the{' '}
                        <Link href={stripeServicesAgreementUrl} target="_blank" external>
                          Stripe Services Agreement
                        </Link>
                        .
                      </Box>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          variant="primary"
                          onClick={() => {
                            if (!setupUrl) {
                              return;
                            }
                            // Redirect to the setup URL
                            window.location.href = setupUrl;
                          }}
                        >
                          Create Account
                        </Button>
                      </div>
                    </SpaceBetween>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {loading ? (
                      error ? (
                        <StatusIndicator type="error">Error</StatusIndicator>
                      ) : (
                        <Spinner size="large" />
                      )
                    ) : (
                      !setupUrl && <StatusIndicator type="success">Success</StatusIndicator>
                    )}
                  </div>
                </SpaceBetween>
              </Container>
              {error && (
                <Alert header="Setup Error" statusIconAriaLabel="Error" type="error">
                  An error occurred while setting up your account. Please try again later. Details:{' '}
                  {error}
                </Alert>
              )}
            </SpaceBetween>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      headerVariant="high-contrast"
      navigationHide
      toolsHide
    />
  );
}
