import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';

import { Order, OrderMeta } from '../types';

import { getQueryResource } from './query';
import axiosInstance from './axiosInstance';

export async function getOrders(
  query?: PropertyFilterQuery,
  page = 1,
  limit = 50,
  range?: DateRangePickerProps.Value | null,
  now = new Date(),
) {
  return await getQueryResource<Order>('/orders', query, page, limit, range, now, 'rent_time');
}

export async function getOrdersMeta() {
  const response = await axiosInstance.get('/orders/meta');

  return response.data as OrderMeta;
}
