import { StatusIndicator } from '@cloudscape-design/components';

export type PercentStatusProps = {
  value?: number;
  total: number;
  max_percent?: number;
  min_percent?: number;
};

export function PercentStatus(props: PercentStatusProps) {
  const { value, total, max_percent, min_percent } = props;

  const fracContent = `${
    value === undefined ? '?' : value.toLocaleString()
  } / ${total.toLocaleString()}`;
  const percentContent = `${
    value === undefined ? '?' : total === 0 ? '-' : ((value / total) * 100).toFixed(0)
  }%`;
  const content = `${fracContent} (${percentContent})`;

  if (value === undefined) {
    return <StatusIndicator type="info">{content}</StatusIndicator>;
  }

  const percent = (value / total) * 100;

  if (isNaN(percent)) {
    return <StatusIndicator type="stopped">{content}</StatusIndicator>;
  } else if (percent >= (max_percent ?? 100)) {
    return <StatusIndicator type="success">{content}</StatusIndicator>;
  } else if (percent > (min_percent ?? 0)) {
    return <StatusIndicator type="warning">{content}</StatusIndicator>;
  }
 
    return <StatusIndicator type="error">{content}</StatusIndicator>;
  
}
