import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  ColumnLayout,
  Grid,
  Button,
} from '@cloudscape-design/components';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import UserContext from '../common/user';

export default function Account() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Account', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Your Account</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  xs: 6,
                  m: 4,
                },
              },
              {
                colspan: {
                  default: 12,
                  xs: 6,
                  m: 4,
                },
              },
            ]}
          >
            <Container
              header={
                <Header
                  actions={<Button onClick={() => navigate('/account/update')}>Update</Button>}
                  variant="h2"
                >
                  Account Details
                </Header>
              }
            >
              <ColumnLayout columns={1} variant="text-grid">
                <div>
                  <Box variant="awsui-key-label">Email</Box>
                  <div>{user?.email}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Password</Box>
                  <div>************</div>
                </div>
              </ColumnLayout>
            </Container>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
