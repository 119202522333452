import { useCallback, useContext, useState } from 'react';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import { useApi, useApiNoBody, useRefreshApi } from '../api';
import UserContext from '../user';
import { UserTokenInfo } from '../types';

import {
  ChatMessage,
  ChatMessagePoll,
  ChatSession,
  ChatSessionResponse,
  GraphMessage,
  LoadingMessage,
  MessageRequest,
  StreamObject,
  TableMessage,
  TextMessage,
  ValueMessage,
  PieChartMessage,
} from './types';

export function useChat(apiUrl: string) {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [refreshApi, refreshError, refreshLoading] = useRefreshApi();
  const [chat, setChat] = useState<ChatSession>();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [createApi] = useApi<MessageRequest, ChatSession>(`/chat`, 'POST');

  const [getApi] = useApiNoBody<ChatSessionResponse>(`/chat`, 'GET');

  const [pollApi] = useApiNoBody<ChatMessagePoll>(`/chat`, 'GET');

  const [sendApi] = useApi<MessageRequest, ChatSession>(`/chat`, 'POST');
  const [error, setError] = useState<string>('');

  // are we loading the entire chat from scratch?
  const [loading, setLoading] = useState<boolean>(false);
  // are we waiting for a response from the assistant?
  const [awaiting, setAwaiting] = useState<boolean>(false);
  // are we streaming responses from the assistant?
  const [streaming, setStreaming] = useState<boolean>(false);
  const [startedStream, setStartedStream] = useState<boolean>(false);

  const streamResponse = useCallback(async () => {
    if (!chat || chat.run_id || !streaming || !awaiting || loading) {
      return;
    }

    if (startedStream) {
      return;
    }
    setStartedStream(true);
    console.log('streaming response');
    const url = `${apiUrl}/chat/${chat.uuid}/stream`;
    // TODO handle refresh token here
    const token = localStorage.getItem('token');

    if (user && user.exp < Date.now() / 1000) {
      const result = await refreshApi();

      if (result.error) {
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        console.error(result.error);
        setError(result.error);
        setStreaming(false);
        setStartedStream(false);
        navigate('/login');

        return;
      } else if (result.data) {
        const newToken = result.data.token;
        localStorage.setItem('token', newToken);
        setUser(newToken ? (jwt(newToken) as UserTokenInfo) : null);
      }
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok || response.body === null) {
      setStreaming(false);
      setStartedStream(false);
      setError('Error streaming response');
      console.log(`Error streaming response: ${response.status}`);

      return;
    }

    try {
      const reader = response.body.getReader();
      await readChatStream(reader, setMessages);
      setStreaming(false);
      setStartedStream(false);
      setAwaiting(false);
      setError('');
      setChat({ ...chat, run_id: undefined });
    } catch (e) {
      console.error(e);
      setStartedStream(false);
      setStreaming(false);
      setError('Error streaming response');
    }
  }, [chat, awaiting, loading, streaming, startedStream]);

  const getChat = async (chatId: string) => {
    console.log('getting chat');
    setLoading(true);
    setError('');
    const response = await getApi(undefined, `${chatId}`);

    if (response.data) {
      setChat(response.data.chat);
      setMessages(response.data.messages);
      console.log(response.data.messages);
      // determine if awaiting from chat
      // if undefined, then not awaiting
      // if defined, then awaiting
      setAwaiting(!!response.data.chat.run_id);
      setLoading(false);
      setError('');
      console.log(response.data.chat);
      console.log(response.data.messages);
    } else if (response.error) {
      setError(response.error);
    }
  };

  const addMessage = useCallback(
    async (message: string) => {
      const text = message.trim();

      if (loading || awaiting || streaming) return;

      if (!text) {
        return;
      }

      if (chat && chat.run_id) {
        return;
      }
      console.log('adding message');
      setError('');
      setAwaiting(true);
      setStreaming(true);
      setStartedStream(false);

      const newMessage: TextMessage = {
        type: 'text',
        created_at: new Date().toISOString().split('.')[0],
        role: 'user',
        message: text,
      };
      const newLoadingMessage: LoadingMessage = {
        type: 'loading',
        created_at: new Date().toISOString().split('.')[0],
        role: 'assistant',
      };

      const oldMessages = [...messages];
      // preemptively adding user's message to messages to look more responsive
      // better handling of reverting due to errors
      setMessages([newLoadingMessage, newMessage, ...messages]);

      if (!chat) {
        console.log('creating chat');
        const response = await createApi({ message: text });

        if (response.data) {
          setChat(response.data);
        } else if (response.error) {
          setError(response.error);
          setAwaiting(false);
          setStreaming(false);
          setStartedStream(false);
          setMessages(oldMessages);
        }
      } else {
        console.log('sending to chat');
        const response = await sendApi({ message: text }, `${chat.uuid}`);

        if (response.data) {
          setChat(response.data);
        } else if (response.error) {
          setError(response.error);
          setAwaiting(false);
          setStreaming(false);
          setStartedStream(false);
          setMessages(oldMessages);
        }
      }
    },
    [chat, messages, loading, awaiting, streaming],
  );

  const pollResponse = useCallback(async () => {
    // TODO may be more conditions for when to poll or avoid polling
    if (!chat || !chat.run_id || loading || streaming || !awaiting) {
      return;
    }
    console.log('polling response');
    const response = await pollApi(undefined, `${chat.uuid}/poll`);

    if (response.data) {
      const poll = response.data;

      if (poll.status === 'completed' && poll.message) {
        console.log('poll completed');
        // status=completed -> add message to messages
        setChat({ ...chat, run_id: undefined });
        setMessages([poll.message, ...messages]);
        setAwaiting(false);
        setError('');
      } else if (poll.status === 'inactive') {
        // status=inactive -> no active run. May need to reload messages, as we may have missed the last message
        // reload messages, but do non-invasively
        await getChat(chat.uuid);
      } else if (poll.status === 'error') {
        // status=error -> look at error
        // TODO handle errors here, this means OpenAI had an error, will need to decide what to do
      } // else {
      // status=in_progress -> keep polling
      // }
    } else if (response.error) {
      // no need to really do anything for a single failed poll
      // if this poll fails, we should keep polling but may get inactive state
      // TODO may need to handle multiple failures
    }
  }, [chat, loading, streaming, awaiting]);

  const resetChat = () => {
    setChat(undefined);
    setMessages([]);
    setAwaiting(false);
    setStreaming(false);
    setStartedStream(false);
    setLoading(false);
    setError('');
  };

  return {
    chat,
    messages,
    loading,
    awaiting,
    streaming,
    error,
    addMessage,
    pollResponse,
    streamResponse,
    getChat,
    resetChat,
  };
}

async function readChatStream(
  reader: ReadableStreamDefaultReader<Uint8Array>,
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
) {
  const decoder = new TextDecoder('utf-8');

  // TODO may need to check for external conditions to break loop, such as a cancelation
  // Unexpected constant condition  no-constant-condition
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read();

    if (done) {
      break;
    }

    const chunk = decoder.decode(value, { stream: true });
    const lines = chunk.split('\n').filter((line) => line.trim() !== '');

    const parsedObjects = lines.map((line) => JSON.parse(line)) as StreamObject[];

    for (const obj of parsedObjects) {
      console.info(obj);

      if (obj.type === 'stream.end') {
        break;
      } else if (obj.type === 'text.delta') {
        setMessages((messages) => {
          const lastMessage = messages[0];

          if (lastMessage.role === 'assistant' && lastMessage.type === 'text') {
            // append to last message
            const newMessage = {
              ...lastMessage,
              message: lastMessage.message + obj.value,
            };

            return [newMessage, ...messages.slice(1)];
          }

          // create new message
          const newMessage: TextMessage = {
            type: 'text',
            created_at: new Date().toISOString().split('.')[0],
            role: 'assistant',
            message: obj.value,
          };

          if (lastMessage.role === 'assistant' && lastMessage.type === 'loading') {
            // remove loading message
            return [newMessage, ...messages.slice(1)];
          }

          return [newMessage, ...messages];
        });
      } else if (obj.type === 'tool.call.created') {
        // TODO have tool.call.failed obj events to stop loading
        // create new assistant message showing tool in progress
        setMessages((messages) => {
          if (messages[0].role === 'assistant' && messages[0].type === 'loading') {
            const lastMessage = messages[0];
            const newMessage: LoadingMessage = {
              type: 'loading',
              created_at: lastMessage.created_at,
              role: 'assistant',
              name: obj.name,
            };

            return [newMessage, ...messages.slice(1)];
          }

          const newMessage: LoadingMessage = {
            type: 'loading',
            created_at: new Date().toISOString().split('.')[0],
            role: 'assistant',
            name: obj.name,
          };

          return [newMessage, ...messages];
        });
      } else if (obj.type === 'tool.call.done') {
        if (obj.name === 'run_sql') {
          const newMessage: LoadingMessage = {
            type: 'loading',
            created_at: new Date().toISOString().split('.')[0],
            role: 'assistant',
            name: obj.name,
            complete: true,
          };
          setMessages((messages) => {
            // first remove the loading message
            return [newMessage, ...messages.slice(1)];
          });
        } else if (obj.name === 'show_table') {
          const newMessage: TableMessage = {
            type: 'table',
            created_at: new Date().toISOString().split('.')[0],
            role: 'assistant',
            title: obj.value.title,
            rows: obj.value.rows,
            columns: obj.value.columns,
          };
          // modify assistant message showing tool results
          setMessages((messages) => {
            // first remove the loading message
            return [newMessage, ...messages.slice(1)];
          });
        } else if (obj.name === 'show_graph') {
          const newMessage: GraphMessage = {
            type: 'graph',
            created_at: new Date().toISOString().split('.')[0],
            role: 'assistant',
            title: obj.value.title,
            variant: obj.value.variant,
            x: obj.value.x,
            x_type: obj.value.x_type,
            x_label: obj.value.x_label,
            y_label: obj.value.y_label,
            series: obj.value.series,
          };
          // modify assistant message showing tool results
          setMessages((messages) => {
            // first remove the loading message
            return [newMessage, ...messages.slice(1)];
          });
        } else if (obj.name === 'show_value') {
          const newMessage: ValueMessage = {
            type: 'value',
            created_at: new Date().toISOString().split('.')[0],
            role: 'assistant',
            title: obj.value.title,
            subtitle: obj.value.subtitle,
            value_type: obj.value.type,
            value: obj.value.value,
            prev_value: obj.value.prev_value,
          };
          // modify assistant message showing tool results
          setMessages((messages) => {
            // first remove the loading message
            return [newMessage, ...messages.slice(1)];
          });
        } else if (obj.name === 'show_pie') {
          const newMessage: PieChartMessage = {
            type: 'pie',
            created_at: new Date().toISOString().split('.')[0],
            role: 'assistant',
            title: obj.value.title,
            x_label: obj.value.x_label,
            y_label: obj.value.y_label,
            y_type: obj.value.y_type,
            x: obj.value.x,
            y: obj.value.y,
          };
          // modify assistant message showing tool results
          setMessages((messages) => {
            // first remove the loading message
            return [newMessage, ...messages.slice(1)];
          });
        } else {
          console.error('Unknown obj type: ', obj);
        }
      } else if (obj.type === 'tool.call.error') {
        const newMessage: LoadingMessage = {
          type: 'loading',
          created_at: new Date().toISOString().split('.')[0],
          role: 'assistant',
          name: obj.name,
          error: true,
        };
        setMessages((messages) => {
          // first remove the loading message
          return [newMessage, ...messages.slice(1)];
        });
      }
    }
  }
}
