import { Header, PieChart, SpaceBetween } from '@cloudscape-design/components';

import { PieChartMessage } from '../common/chat/types';
import { formatFinancial } from '../common/formatting';

export type ChatPieChartProps = {
  message: PieChartMessage;
};

export function ChatPieChart(props: ChatPieChartProps) {
  const message = props.message;

  const x = message.x as string[];
  const y = message.y as number[];

  const data = x
    .map((v, i) => ({
      title: v,
      value: y[i],
    }))
    .toSorted((a, b) => b.value - a.value);

  const chart = (
    <div
      style={{
        // 400 is the minimum height, additional math due to the annotations on the chart sometimes being cut off
        height: 400 + Math.max(x.length - 14, 0) * 20,
      }}
    >
      <PieChart
        data={data}
        detailPopoverContent={(d, s) => [
          {
            key: d.title,
            value:
              message.y_type === 'number'
                ? d.value.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })
                : formatFinancial(d.value, 0),
          },
          {
            key: 'Percentage',
            value: `${((d.value / y.reduce((a, b) => a + b, 0)) * 100).toFixed(0)}%`,
          },
        ]}
        size="large"
        statusType="finished"
        hideFilter
        hideLegend
      />
    </div>
  );

  return (
    <SpaceBetween direction="vertical" size="xs">
      <Header variant="h2">{message.title}</Header>
      {chart}
    </SpaceBetween>
  );
}
