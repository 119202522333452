import { Button, Container, Input } from '@cloudscape-design/components';
import { createRef, useEffect, useState } from 'react';

export type ChatBarProps = {
  addMessage: (message: string) => void;
  loading: boolean;
  error?: string;
};

export function ChatBar(props: ChatBarProps) {
  const { addMessage, loading } = props;
  const [message, setMessage] = useState('');

  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (inputRef.current && !loading) {
      inputRef.current.focus();
    }
  }, [inputRef, loading]);

  return (
    <div className="escape-chat-box">
      <Container>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              flex: 1,
              marginRight: '8px',
            }}
          >
            <Input
              ref={inputRef}
              disabled={loading}
              placeholder="Message Zappy Chat"
              value={message}
              autoFocus
              onChange={({ detail }) => setMessage(detail.value)}
              onKeyDown={(e) => {
                if (e.detail.key === 'Enter' && !loading) {
                  addMessage(message);
                  setMessage('');
                }
              }}
            />
          </div>
          <Button
            disabled={loading}
            iconName="upload"
            variant="primary"
            onClick={() => {
              if (!loading) {
                addMessage(message);
                setMessage('');
              }
            }}
          />
        </div>
      </Container>
    </div>
  );
}
