import {
  PropertyFilterOption,
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import { useMemo, useState } from 'react';

import { DeviceWithVenue, Venue } from '../common/types';
import { useApiNoBody } from '../common/api';
import {
  FilterConfig,
  createFilter,
  filterNumber,
  filterPercentageOrNumber,
  filterText,
  filterTextMatch,
  useUrlFilterState,
} from '../common/filter';
import { IGNORE_VENUES } from '../common/venue';

export const DEVICE_FILTERS: PropertyFilterProperty[] = [
  {
    key: 'device',
    operators: ['=', '!='],
    propertyLabel: 'ID',
    groupValuesLabel: 'Device IDs',
  },
  {
    key: 'status',
    operators: ['=', '!='],
    propertyLabel: 'Status',
    groupValuesLabel: 'Device Statuses',
  },
  {
    key: 'venue',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Venue',
    groupValuesLabel: 'Device venues',
  },
  {
    key: 'source',
    operators: ['=', '!='],
    propertyLabel: 'Source',
    groupValuesLabel: 'Sources',
  },
  {
    key: 'slots',
    operators: ['=', '!=', '>', '>=', '<', '<='],
    propertyLabel: 'Slots',
    groupValuesLabel: 'Device Max Slots',
  },
  {
    key: 'full_slots',
    operators: ['=', '!=', '>', '>=', '<', '<='],
    propertyLabel: 'Full Slots',
    groupValuesLabel: 'Full Slots for Rental/Purchase',
  },
  {
    key: 'fulfillment',
    operators: ['=', '!='],
    propertyLabel: 'Fulfillment',
    groupValuesLabel: 'Fulfillment Status',
  },
];

export const VENUE_FILTER_CONFIGS: FilterConfig<DeviceWithVenue>[] = [
  {
    propertyKey: 'device',
    func: (token, item) => filterTextMatch(token, item.device_id),
  },
  {
    propertyKey: 'status',
    func: (token, item) => filterTextMatch(token, item.status),
  },
  {
    propertyKey: 'venue',
    func: (token, item) => filterText(token, item.venue.name),
  },
  {
    propertyKey: 'source',
    func: (token, item) => filterTextMatch(token, item.vendor.name === 'Relink' ? 'Uber' : 'Kiosk'),
  },
  {
    propertyKey: 'full_slots',
    func: (token, item) => filterPercentageOrNumber(token, item.full_slots, item.total_slots),
  },
  {
    propertyKey: 'slots',
    func: (token, item) => filterNumber(token, item.slots),
  },
  {
    propertyKey: 'fulfillment',
    func: (token, item) =>
      filterTextMatch(token, item.kiosk?.uninstall_date ? 'Uninstalled' : 'Installed'),
  },
];

export type DeviceFilterValues = {
  statuses: string[];
  venues: Venue[];
  devices: DeviceWithVenue[];
  sources: string[];
  slots: number[];
  active_slots: number[];
  fulfillment: string[];
};

export function getFilterValues(items: DeviceWithVenue[]): DeviceFilterValues {
  const statuses = new Set<string>();
  const venues: Venue[] = [];
  const venueNames = new Set<string>();
  const devices: DeviceWithVenue[] = [];
  const deviceNames = new Set<string>();
  const sources = new Set<string>();
  const slots = new Set<number>();
  const active_slots = new Set<number>();
  const fulfillment = new Set<string>();

  for (const item of items) {
    statuses.add(item.status);

    if (!venueNames.has(item.venue.venue_id)) {
      venues.push(item.venue);
    }
    venueNames.add(item.venue.venue_id);

    if (!deviceNames.has(item.device_id)) {
      devices.push(item);
    }
    deviceNames.add(item.device_id);
    sources.add(item.vendor.name === 'Relink' ? 'Uber' : 'Kiosk');
    slots.add(item.slots);
    active_slots.add(item.active_slots);
    fulfillment.add(item.kiosk?.uninstall_date ? 'Uninstalled' : 'Installed');
  }
  venues.sort((a, b) => a.name.localeCompare(b.name));
  devices.sort((a, b) => a.device_id.localeCompare(b.device_id));

  return {
    statuses: Array.from(statuses),
    venues,
    devices,
    sources: Array.from(sources),
    slots: Array.from(slots),
    active_slots: Array.from(active_slots),
    fulfillment: Array.from(fulfillment),
  };
}

export function getVenues(items: DeviceWithVenue[]): Venue[] {
  const venues: Venue[] = [];
  const venueNames = new Set<string>();

  for (const item of items) {
    if (!venueNames.has(item.venue.venue_id)) {
      venues.push(item.venue);
    }
    venueNames.add(item.venue.venue_id);
  }
  venues.sort((a, b) => a.name.localeCompare(b.name));

  return venues;
}

export function useDeviceState() {
  const [query, setQuery] = useState<PropertyFilterQuery>({
    operation: 'and',
    tokens: [],
  });

  return {
    query,
    setQuery,
  };
}

export function useDeviceFilterState(
  apiItems: DeviceWithVenue[],
  setApiItems: (items: DeviceWithVenue[]) => void,
  query: PropertyFilterQuery,
  setQuery: (query: PropertyFilterQuery) => void,
  route?: string,
) {
  const filterValues = useMemo(() => getFilterValues(apiItems), [apiItems]);

  const propertyFilteringOptions: PropertyFilterOption[] = [];
  filterValues.venues.sort();

  for (const venue of filterValues.venues) {
    propertyFilteringOptions.push({
      propertyKey: 'venue',
      value: venue.name,
    });
  }

  filterValues.devices.sort();

  for (const device of filterValues.devices) {
    propertyFilteringOptions.push({
      propertyKey: 'device',
      value: device.device_id,
    });
  }

  filterValues.statuses.sort();

  for (const payment_status of filterValues.statuses) {
    propertyFilteringOptions.push({
      propertyKey: 'status',
      value: payment_status,
    });
  }

  filterValues.fulfillment.sort();

  for (const fulfill_status of filterValues.fulfillment) {
    propertyFilteringOptions.push({
      propertyKey: 'fulfillment',
      value: fulfill_status,
    });
  }

  filterValues.sources.sort();

  for (const source of filterValues.sources) {
    propertyFilteringOptions.push({
      propertyKey: 'source',
      value: source,
    });
  }

  filterValues.slots.sort();

  for (const slot of filterValues.slots) {
    propertyFilteringOptions.push({
      propertyKey: 'slots',
      value: `${slot}`,
    });
  }

  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: `0`,
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: `${Math.max(...filterValues.slots)}`,
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: '0%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: '10%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: '50%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: '100%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: '?',
  });

  const apiPath = route ? route : '/devices';

  const [api, error, loading] = useApiNoBody<DeviceWithVenue[]>(apiPath, 'GET', (devices) => {
    const filteredDevices = devices
      .filter((device) => {
        if (IGNORE_VENUES.includes(device.venue.name)) {
          return false;
        }

        return true;
      })
      .map((device) => {
        const unknown = device.status === 'Unknown';
        const empty_slots = device.active_slots;
        let full_slots: number | undefined = device.slots - empty_slots;

        if (unknown) {
          full_slots = undefined;
        }

        return {
          ...device,
          total_slots: device.slots,
          full_slots,
        };
      });
    setApiItems(filteredDevices);
  });

  const fetchApi = async () => {
    await api();
  };

  const filterTable = createFilter<DeviceWithVenue>(query, VENUE_FILTER_CONFIGS);

  const filteredItems = apiItems.filter(filterTable);

  return {
    fetchApi,
    query,
    setQuery,
    propertyFilteringOptions,
    filteredItems,
    loading,
    error,
  };
}

export function useDevices(route?: string) {
  const [apiItems, setApiItems] = useState<DeviceWithVenue[]>([]);
  const { query, setQuery } = useDeviceState();

  return useDeviceFilterState(apiItems, setApiItems, query, setQuery, route);
}

export function useDevicesWithUrl(route?: string) {
  const [apiItems, setApiItems] = useState<DeviceWithVenue[]>([]);
  const { query, setQuery } = useUrlFilterState();

  return useDeviceFilterState(apiItems, setApiItems, query, setQuery, route);
}

export function useVenues() {
  const [apiItems, setApiItems] = useState<DeviceWithVenue[]>([]);
  const venues = useMemo(() => getVenues(apiItems), [apiItems]);
  const [api, error, loading] = useApiNoBody<DeviceWithVenue[]>('/devices', 'GET', setApiItems);

  const fetchApi = async () => {
    await api();
  };

  return {
    venues,
    fetchApi,
    loading,
    error,
  };
}
