import {
  PropertyFilterOption,
  PropertyFilterQuery,
  useCollection,
} from '@cloudscape-design/collection-hooks';
import {
  Box,
  Header,
  SpaceBetween,
  Button,
  Table,
  Pagination,
  PropertyFilter,
} from '@cloudscape-design/components';
import { ReactNode, useEffect } from 'react';

import { DeviceWithVenue } from '../common/types';
import { getHeaderCounterText } from '../common/full-header';
import { DeviceStatusIcon } from '../common/devices';
import { PercentStatus } from '../common/percent-status';
import { VenueLink } from '../venues/link';
import { exportExcel } from '../common/excel';

import { DEVICE_FILTERS, useDevicesWithUrl } from './device-filtering';
import { DeviceLink } from './link';

export type DeviceFilterProps = {
  query: PropertyFilterQuery;
  setQuery: (query: PropertyFilterQuery) => void;
  propertyFilteringOptions: PropertyFilterOption[];
  loading: boolean;
  skipColumns?: string[];
};

export function DeviceFilter(props: DeviceFilterProps) {
  const { query, setQuery, propertyFilteringOptions, loading } = props;

  return (
    <PropertyFilter
      disabled={loading}
      filteringOptions={propertyFilteringOptions}
      filteringProperties={DEVICE_FILTERS.filter((f) => !props.skipColumns?.includes(f.key))}
      query={query}
      onChange={({ detail }) => setQuery(detail)}
    />
  );
}

export function StandaloneDeviceTable() {
  const { fetchApi, query, setQuery, propertyFilteringOptions, filteredItems, loading, error } =
    useDevicesWithUrl();

  useEffect(() => {
    fetchApi();
  }, []);

  const itemFilter = (
    <DeviceFilter
      loading={loading}
      propertyFilteringOptions={propertyFilteringOptions}
      query={query}
      setQuery={setQuery}
    />
  );

  return (
    <DeviceTable
      error={error}
      fetchApi={fetchApi}
      filteredItems={filteredItems}
      itemFilter={itemFilter}
      loading={loading}
      pageSize={15}
      fullPage
    />
  );
}

export type DeviceTableProps = {
  fetchApi: () => void;
  filteredItems: DeviceWithVenue[];
  loading: boolean;
  error: string | null;
  itemFilter?: ReactNode;
  actions?: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
  skipColumns?: string[];
  pageSize?: number;
  fullPage?: boolean;
};

export function DeviceTable(props: DeviceTableProps) {
  const pageSize = props.pageSize ?? 10;
  const resourceName = 'Device';

  const { fetchApi, filteredItems, loading, error } = props;

  const { items, collectionProps, paginationProps } = useCollection(filteredItems, {
    pagination: { pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'id',
          sortingComparator(a, b) {
            return a.device_id.localeCompare(b.device_id);
          },
        },
        isDescending: false,
      },
    },
  });

  return (
    <Table
      stickyHeader={props.fullPage}
      variant={props.fullPage ? 'full-page' : undefined}
      {...collectionProps}
      {...paginationProps}
      columnDefinitions={[
        {
          id: 'id',
          header: 'ID',
          cell: (e: DeviceWithVenue) => <DeviceLink device={e} />,
          sortingField: 'id',
          sortingComparator(a: DeviceWithVenue, b: DeviceWithVenue) {
            return a.device_id.localeCompare(b.device_id);
          },
        },
        {
          id: 'status',
          header: 'Status',
          cell: (e: DeviceWithVenue) => <DeviceStatusIcon status={e.status} />,
          sortingField: 'status',
          sortingComparator(a: DeviceWithVenue, b: DeviceWithVenue) {
            return a.status.localeCompare(b.status);
          },
        },
        {
          id: 'venue',
          header: 'Venue',
          cell: (e: DeviceWithVenue) => <VenueLink venue={e.venue} />,
          sortingField: 'venue',
          sortingComparator(a: DeviceWithVenue, b: DeviceWithVenue) {
            return a.venue.name.localeCompare(b.venue.name);
          },
        },
        {
          id: 'position',
          header: 'Position',
          cell: (e: DeviceWithVenue) => e.kiosk?.position_description ?? 'N/A',
          sortingField: 'position',
          sortingComparator(a: DeviceWithVenue, b: DeviceWithVenue) {
            return (a.kiosk?.position_description ?? 'N/A').localeCompare(
              b.kiosk?.position_description ?? 'N/A',
            );
          },
        },
        // {
        //   id: 'source',
        //   header: 'Source',
        //   cell: (e: DeviceWithVenue) => (e.vendor.name === 'Relink' ? 'Uber' : 'Kiosk'),
        //   sortingField: 'source',
        //   sortingComparator(a: DeviceWithVenue, b: DeviceWithVenue) {
        //     const a_name = a.vendor.name === 'Relink' ? 'Uber' : 'Kiosk';
        //     const b_name = b.vendor.name === 'Relink' ? 'Uber' : 'Kiosk';

        //     return a_name.localeCompare(b_name);
        //   },
        // },
        {
          id: 'full_slots',
          header: 'Full Slots',
          cell: (e: DeviceWithVenue) => (
            <PercentStatus
              max_percent={50}
              min_percent={10}
              total={e.total_slots}
              value={e.full_slots}
            />
          ),
          sortingField: 'full_slots',
          sortingComparator(a: DeviceWithVenue, b: DeviceWithVenue) {
            return (
              ((a.total_slots === 0 ? -2 : a.full_slots) ?? -1) -
              ((b.total_slots === 0 ? -2 : b.full_slots) ?? -1)
            );
          },
        },
        {
          id: 'fulfillment',
          header: 'Fulfillment',
          cell: (e: DeviceWithVenue) => (e.kiosk?.uninstall_date ? 'Uninstalled' : 'Installed'),
          sortingField: 'fulfillment',
          sortingComparator(a: DeviceWithVenue, b: DeviceWithVenue) {
            return (a.kiosk?.uninstall_date ? 'Uninstalled' : 'Installed').localeCompare(
              b.kiosk?.uninstall_date ? 'Uninstalled' : 'Installed',
            );
          },
        },
      ].filter((column) => !props.skipColumns?.includes(column.id))}
      empty={
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {error}
                </Box>
              </div>
              <Button onClick={fetchApi}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      }
      filter={props.itemFilter}
      header={
        <Header
          actions={
            props.actions ?? (
              <SpaceBetween direction="horizontal" size="s">
                <Button disabled={loading} iconName="refresh" onClick={fetchApi} />
                <Button
                  disabled={loading}
                  iconName="download"
                  onClick={() => {
                    exportExcel(
                      filteredItems.map((item) => ({
                        id: item.device_id,
                        status: item.status,
                        venue: item.venue.name,
                        position: item.kiosk?.position_description ?? 'N/A',
                        source: item.vendor.name === 'Relink' ? 'Uber' : 'Kiosk',
                        full_slots: item.full_slots ?? 0,
                        total_slots: item.total_slots,
                        fulfillment: item.kiosk?.uninstall_date ? 'Uninstalled' : 'Installed',
                      })),
                      'devices',
                    );
                  }}
                />
              </SpaceBetween>
            )
          }
          counter={getHeaderCounterText(filteredItems, collectionProps.selectedItems)}
          description={props.description}
          variant={props.fullPage ? 'awsui-h1-sticky' : undefined}
        >
          {props.title ?? `${resourceName}s`}
        </Header>
      }
      items={items}
      loading={loading}
      loadingText={`Loading ${resourceName.toLowerCase()}s`}
      pagination={<Pagination {...paginationProps} disabled={loading} />}
      trackBy="id"
    />
  );
}
