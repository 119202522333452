import {
  Container,
  Box,
  Header,
  SpaceBetween,
  Button,
  ColumnLayout,
  Spinner,
  Alert,
  PieChart,
  DateRangePickerProps,
} from '@cloudscape-design/components';
import { ReactNode, useMemo } from 'react';

import { DeviceWithOrders, DeviceWithUptime, Order } from '../common/types';
import { getDuration } from '../common/orders';
import { rangeStartEnd } from '../common/filter';
import { computeStats } from '../common/stats';

export type GroupedDeviceWithOrders = {
  id: string;
  slots: number;
  orders: Order[];
  revenue: number;
  rps: number;
};

export function groupByDevice(orders: Order[]): GroupedDeviceWithOrders[] {
  const devices: { [key: string]: GroupedDeviceWithOrders } = {};
  orders.forEach((order) => {
    const d_id = order.device.device_id;

    if (!(d_id in devices)) {
      devices[d_id] = {
        id: d_id,
        slots: order.order_type === 'Uber' ? 6 : order.device.slots,
        orders: [],
        revenue: 0,
        rps: 0,
      };
    }
    devices[d_id].orders.push(order);
  });

  for (const key in devices) {
    const device = devices[key];
    device.revenue = device.orders.reduce(
      (total, order) => total + parseFloat(order.settlement_amount),
      0,
    );
    device.rps = device.revenue / device.slots;
  }

  const deviceList = Object.keys(devices).map((key) => devices[key]);
  deviceList.sort((a, b) => b.orders.length - a.orders.length);

  return deviceList;
}

export type OrderStatusPieProps = {
  items: Order[];
  loading: boolean;
  error: string | null;
  fetchApi: () => void;
  reduce: (item: Order) => string;
};

export function OrderStatusPie(props: OrderStatusPieProps) {
  const { items, loading, error, fetchApi } = props;
  const resourceName = 'Order';

  const statusCounts = useMemo(() => {
    const counts: { [key: string]: number } = {};
    items.forEach((item) => {
      const status = props.reduce(item);
      counts[status] = (counts[status] ?? 0) + 1;
    });

    return counts;
  }, [items]);

  const statusCount = Object.keys(statusCounts).map((key) => ({
    title: key,
    value: statusCounts[key],
  }));
  statusCount.sort((a, b) => b.value - a.value);

  return (
    <PieChart
      ariaDescription="Pie chart showing how many orders have each status."
      ariaLabel="Pie chart"
      data={statusCount}
      detailPopoverSize="small"
      empty={
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {error}
                </Box>
              </div>
              <Button onClick={fetchApi}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      }
      errorText={error ?? undefined}
      loadingText=""
      size="small"
      statusType={loading ? 'loading' : error ? 'error' : 'finished'}
      hideFilter
      hideLegend
    />
  );
}

export type OrderReportProps = {
  fetchApi: () => void;
  orders: Order[];
  devices: DeviceWithOrders[];
  range: DateRangePickerProps.Value | null;
  loading: boolean;
  error: string | null;
  itemFilter?: ReactNode;
  actions?: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
};

export function OrderReport(props: OrderReportProps) {
  const { orders: filteredItems, loading, error, itemFilter, devices } = props;

  const { start, end } = useMemo(
    () => (props.range ? rangeStartEnd(props.range) : { start: new Date(), end: new Date() }),
    [props.range],
  );

  // const avgRps = useMemo(
  //   () => devices.reduce((total, device) => total + device.rps, 0) / devices.length,
  //   [devices],
  // );

  // avg rev per slot per month
  // avg transactions per slot per month
  const stats = useMemo(() => {
    return computeStats(devices, start, end);
  }, [devices]);

  const avgRps = useMemo(
    () => stats.reduce((total, device) => total + device.rps, 0) / Math.max(stats.length, 1),
    [stats],
  );

  const avgTps = useMemo(
    () => stats.reduce((total, device) => total + device.tps, 0) / Math.max(stats.length, 1),
    [stats],
  );

  const revenue = useMemo(
    () => filteredItems.reduce((total, item) => total + parseFloat(item.settlement_amount), 0),
    [filteredItems],
  );

  const rentalReturn = useMemo(
    () =>
      filteredItems.filter(
        (item) => parseFloat(item.settlement_amount) > 0 && parseFloat(item.settlement_amount) < 40,
      ),
    [filteredItems],
  );

  const rentalReturnRevenue = useMemo(
    () => rentalReturn.reduce((total, item) => total + parseFloat(item.settlement_amount), 0),
    [filteredItems],
  );

  const purchases = useMemo(
    () => filteredItems.filter((item) => parseFloat(item.settlement_amount) >= 40),
    [filteredItems],
  );

  const purchasesRevenue = useMemo(
    () => purchases.reduce((total, item) => total + parseFloat(item.settlement_amount), 0),
    [filteredItems],
  );

  const totalDuration = useMemo(
    () => filteredItems.reduce((total, item) => total + (getDuration(item) ?? 0), 0),
    [filteredItems],
  );

  const minutes = Math.floor(totalDuration / (1000 * 60));

  // const online = useMemo(
  //   () => props.uptimes.reduce((total, uptime) => total + uptime.online_hours, 0),
  //   [props.uptimes],
  // );

  return (
    <Container
      header={
        <Header actions={props.actions} description={props.description}>
          {props.title ?? `Report`}
        </Header>
      }
    >
      <SpaceBetween direction="vertical" size="s">
        {itemFilter}
        {loading ? (
          <SpaceBetween direction="horizontal" size="xxs">
            <Spinner />
            <Box>Loading reporting...</Box>
          </SpaceBetween>
        ) : error ? (
          <Alert type="error">{error}</Alert>
        ) : (
          <SpaceBetween direction="vertical" size="m">
            <Container
              footer={
                <div>
                  <Box variant="awsui-key-label">Total Revenue</Box>
                  <Box variant="strong">${revenue.toLocaleString()} USD</Box>
                </div>
              }
              variant="stacked"
            >
              <ColumnLayout columns={2} variant="text-grid">
                <div>
                  <Box variant="awsui-key-label">Rental Revenue</Box>
                  <Box variant="span">${rentalReturnRevenue.toLocaleString()} USD</Box>
                </div>
                <div>
                  <Box variant="awsui-key-label">Sales Revenue</Box>
                  <Box variant="span">${purchasesRevenue.toLocaleString()} USD</Box>
                </div>
              </ColumnLayout>
            </Container>
            <Container
              footer={
                <div>
                  <Box variant="awsui-key-label">Total Orders</Box>
                  <Box variant="strong">{`${filteredItems.length.toLocaleString()} orders`}</Box>
                </div>
              }
              variant="stacked"
            >
              <ColumnLayout columns={2} variant="text-grid">
                <div>
                  <Box variant="awsui-key-label">Rental Orders</Box>
                  <Box variant="span">
                    {(filteredItems.length - purchases.length).toLocaleString()}
                  </Box>
                </div>
                <div>
                  <Box variant="awsui-key-label">Sales Orders</Box>
                  <Box variant="span">{purchases.length.toLocaleString()}</Box>
                </div>
              </ColumnLayout>
            </Container>
            <Container
              footer={
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    {/* TODO add hover info */}
                    <Box variant="awsui-key-label">Average RPM</Box>
                    <Box variant="span">${`${avgRps.toFixed(2)} USD`}</Box>
                  </div>
                  <div>
                    {/* TODO add hover info */}
                    <Box variant="awsui-key-label">Average TPM</Box>
                    <Box variant="span">{`${avgTps.toFixed(1)}`}</Box>
                  </div>
                </ColumnLayout>
              }
              variant="stacked"
            >
              <ColumnLayout columns={2} variant="text-grid">
                {/* <div>
                  <Box variant="awsui-key-label">Online Hours</Box>
                  <Box variant="span">{`${online.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })} hours`}</Box>
                </div> */}
                <div>
                  <Box variant="awsui-key-label">Rental Minutes</Box>
                  <Box variant="span">{`${minutes.toLocaleString()} minutes`}</Box>
                </div>
                {/* <div>
                  <Box variant="awsui-key-label">Active Devices</Box>
                  <Box variant="span">{`${activeDevices.length.toLocaleString()}`}</Box>
                </div>
                <div>
                  <Box variant="awsui-key-label">Active Slots</Box>
                  <Box variant="span">{`${activeSlots.toLocaleString()}`}</Box>
                </div> */}
              </ColumnLayout>
            </Container>
          </SpaceBetween>
        )}
        {/* <Header variant="h3">Order Status</Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <OrderStatusPie
            error={error}
            fetchApi={props.fetchApi}
            items={filteredItems}
            loading={loading}
            reduce={(item) => item.payment_status}
          />
          <OrderStatusPie
            error={error}
            fetchApi={props.fetchApi}
            items={filteredItems}
            loading={loading}
            reduce={(item) => item.rent_status}
          />
        </div> */}
      </SpaceBetween>
    </Container>
  );
}
