import {
  Box,
  Button,
  Header,
  Pagination,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';

import { TableMessage } from '../common/chat/types';
import { formatBig, formatFinancial } from '../common/formatting';

export type ChatTableProps = {
  message: TableMessage;
};

export function ChatTable(props: ChatTableProps) {
  const message = props.message;
  const pageSize = 10;
  const data = message.rows;

  const { items, collectionProps, paginationProps } = useCollection(data, {
    pagination: { pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'created',
          sortingComparator(a, b) {
            const x = new Date(a.created_at);
            const y = new Date(b.created_at);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        isDescending: true,
      },
    },
  });

  return (
    <Table
      {...collectionProps}
      {...paginationProps}
      columnDefinitions={[
        ...message.columns.map((column) => ({
          // id, header, cell, sortingField, sortingComparator
          id: column.name,
          header: column.label,
          cell: (row: { [id: string]: any }) => {
            const value = row[column.name];

            if (column.type === 'currency') {
              return formatFinancial(value as number, 0);
            } else if (column.type === 'number') {
              return formatBig(value as number);
            } else if (column.type === 'datetime') {
              return new Date((value as string) + 'Z').toLocaleString();
            }

            return value as string;
          },
          sortingField: column.name,
          sortingComparator: (a: { [id: string]: any }, b: { [id: string]: any }) => {
            const aValue = a[column.name];
            const bValue = b[column.name];

            if (column.type === 'currency' || column.type === 'number') {
              return (aValue as number) - (bValue as number);
            } else if (column.type === 'datetime') {
              return Date.parse((aValue as string) + 'Z') - Date.parse((bValue as string) + 'Z');
            }

            return (aValue as string).localeCompare(bValue as string);
          },
        })),
      ]}
      empty={
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          <SpaceBetween size="xxs">
            <div>
              <b>No data</b>
              <Box color="inherit" variant="p">
                No data found.
              </Box>
            </div>
          </SpaceBetween>
        </Box>
      }
      header={<Header>{message.title}</Header>}
      items={items}
      pagination={<Pagination {...paginationProps} />}
      variant="borderless"
    />
  );
}
