import { useMemo } from 'react';

import { DeviceStats, DeviceWithOrders, Order } from './types';

export function useDevicesFromOrders(orders: Order[]) {
  const orderFilteredItems = useMemo(() => {
    // device id -> device lookup map
    const deviceMap = new Map<number, DeviceWithOrders>();

    for (const order of orders) {
      if (!deviceMap.has(order.device.id)) {
        deviceMap.set(order.device.id, {
          ...order.device,
          orders: [],
        });
      }

      const device = deviceMap.get(order.device.id);
      device?.orders.push(order);
    }

    return Array.from(deviceMap.values());
  }, [orders]);

  return {
    filteredItems: orderFilteredItems,
  };
}

function isUndefinedRps(rps: number | undefined) {
  return rps === undefined || isNaN(rps) || !isFinite(rps);
}

export function computeStats(devices: DeviceWithOrders[], start: Date, end: Date): DeviceStats[] {
  const days = Math.abs(end.getTime() - start.getTime()) / 864e5;

  return devices
    .map((device) => {
      const device_orders = device.orders.length;
      const device_slots = device.slots;
      const device_revenue = device.orders.reduce(
        (total, order) => total + parseFloat(order.settlement_amount),
        0,
      );

      const first_order = device.orders
        .map((order) => new Date(order.rent_time))
        .reduce((min, order) => {
          return order < min ? order : min;
        }, new Date());

      // const device_hours = Math.abs(end.getTime() - first_order.getTime()) / 36e5;
      const device_hours = days * 24;
      // revenue per slot per hour
      let device_hourly_rps = device_revenue / (device_slots * device_hours);

      if (isUndefinedRps(device_hourly_rps)) {
        device_hourly_rps = 0;
      }

      // amortized revenue per slot per month
      let device_monthly_rps = device_hourly_rps * 24 * 30;

      if (isUndefinedRps(device_monthly_rps)) {
        device_monthly_rps = 0;
      }

      // transactions per slot per hour
      let device_hourly_tps = device_orders / (device_slots * device_hours);

      if (isUndefinedRps(device_hourly_tps)) {
        device_hourly_tps = 0;
      }

      // amortized transactions per slot per month
      let device_monthly_tps = device_hourly_tps * 24 * 30;

      if (isUndefinedRps(device_monthly_tps)) {
        device_monthly_tps = 0;
      }

      return {
        ...device,
        rps: device_monthly_rps,
        tps: device_monthly_tps,
      };
    })
    .filter((device) => device.rps > 0 && device.tps > 0);
}
