import { Header, AppLayout, ContentLayout, Grid } from '@cloudscape-design/components';
import { useContext } from 'react';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';

import { UrlOrderTable } from './table';

export default function OrdersPage() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Rentals', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout>
          <UrlOrderTable />
        </ContentLayout>
      }
      contentType="table"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
