import { Container, Box, Header, ColumnLayout, SpaceBetween } from '@cloudscape-design/components';

import { UserListResponse } from '../../../common/types';

export type UserDetailsProps = {
  user: UserListResponse;
  setUser: (user: UserListResponse) => void;
};

export function UserDetails(props: UserDetailsProps) {
  const { user } = props;

  return (
    <SpaceBetween size="l">
      <Container header={<Header variant="h2">User Details</Header>}>
        <ColumnLayout columns={1} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Email</Box>
            <div>{user.email}</div>
          </div>
        </ColumnLayout>
      </Container>
    </SpaceBetween>
  );
}
