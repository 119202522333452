import {
  PropertyFilterOption,
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import { useMemo, useState } from 'react';

import { VenueWithDevices } from '../common/types';
import { useApiNoBody } from '../common/api';
import {
  FilterConfig,
  createFilter,
  filterNumber,
  filterPercentageOrNumber,
  filterText,
  useUrlFilterState,
} from '../common/filter';
import { IGNORE_VENUES } from '../common/venue';

export const VENUE_FILTERS: PropertyFilterProperty[] = [
  {
    key: 'venue',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Venue Name',
    groupValuesLabel: 'Venue Names',
  },
  {
    key: 'venue_type',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Venue Type',
    groupValuesLabel: 'Venue Types',
  },
  {
    key: 'online',
    operators: ['=', '!=', '>', '>=', '<', '<='],
    propertyLabel: 'Online Devices',
    groupValuesLabel: 'Online Venue Devices',
  },
  {
    key: 'devices',
    operators: ['=', '!=', '>', '>=', '<', '<='],
    propertyLabel: 'Total Devices',
    groupValuesLabel: 'Total Venue Devices',
  },
  {
    key: 'full_slots',
    operators: ['=', '!=', '>', '>=', '<', '<='],
    propertyLabel: 'Full Slots',
    groupValuesLabel: 'Full Venue Slots',
  },
  {
    key: 'slots',
    operators: ['=', '!=', '>', '>=', '<', '<='],
    propertyLabel: 'Total Slots',
    groupValuesLabel: 'Total Venue Slots',
  },
];

export const VENUE_FILTER_CONFIGS: FilterConfig<VenueWithDevices>[] = [
  {
    propertyKey: 'venue',
    func: (token, item) => filterText(token, item.name),
  },
  {
    propertyKey: 'venue_type',
    func: (token, item) => filterText(token, item.type ?? 'Uncategorized'),
  },
  {
    propertyKey: 'online',
    func: (token, item) => filterPercentageOrNumber(token, item.online, item.devices.length),
  },
  {
    propertyKey: 'devices',
    func: (token, item) => filterNumber(token, item.devices.length),
  },
  {
    propertyKey: 'full_slots',
    func: (token, item) => filterPercentageOrNumber(token, item.full_slots, item.total_slots),
  },
  {
    propertyKey: 'slots',
    func: (token, item) => filterNumber(token, item.total_slots),
  },
];

export function useVenueState() {
  const [query, setQuery] = useState<PropertyFilterQuery>({
    operation: 'and',
    tokens: [],
  });

  return {
    query,
    setQuery,
  };
}

export function useVenueFilterState(
  apiItems: VenueWithDevices[],
  setApiItems: (items: VenueWithDevices[]) => void,
  query: PropertyFilterQuery,
  setQuery: (query: PropertyFilterQuery) => void,
) {
  const filterValues = useMemo(() => getFilterValues(apiItems), [apiItems]);

  const propertyFilteringOptions: PropertyFilterOption[] = [];
  filterValues.venues.sort();

  for (const venue of filterValues.venues) {
    propertyFilteringOptions.push({
      propertyKey: 'venue',
      value: venue.name,
    });
  }

  filterValues.venueTypes.sort();

  for (const venueType of filterValues.venueTypes) {
    propertyFilteringOptions.push({
      propertyKey: 'venue_type',
      value: venueType,
    });
  }

  propertyFilteringOptions.push({
    propertyKey: 'devices',
    value: `${filterValues.min_devices}`,
  });
  propertyFilteringOptions.push({
    propertyKey: 'devices',
    value: `${filterValues.max_devices}`,
  });
  propertyFilteringOptions.push({
    propertyKey: 'online',
    value: `${filterValues.min_slots}`,
  });
  propertyFilteringOptions.push({
    propertyKey: 'online',
    value: `${filterValues.max_slots}`,
  });
  propertyFilteringOptions.push({
    propertyKey: 'online',
    value: '0%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'online',
    value: '10%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'online',
    value: '50%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'online',
    value: '100%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'online',
    value: '?',
  });

  propertyFilteringOptions.push({
    propertyKey: 'slots',
    value: `${filterValues.min_slots}`,
  });
  propertyFilteringOptions.push({
    propertyKey: 'slots',
    value: `${filterValues.max_slots}`,
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: `${filterValues.min_slots}`,
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: `${filterValues.max_slots}`,
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: '0%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: '10%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: '50%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: '100%',
  });
  propertyFilteringOptions.push({
    propertyKey: 'full_slots',
    value: '?',
  });

  const [api, error, loading] = useApiNoBody<VenueWithDevices[]>('/venues', 'GET', (venues) => {
    const filteredVenues = venues
      .filter((venue) => {
        if (IGNORE_VENUES.includes(venue.name)) {
          return false;
        }

        return true;
      })
      .map((venue) => {
        let online: number | undefined = venue.devices.filter(
          (device) => device.status === 'Online',
        ).length;
        const unknown = venue.devices.filter((device) => device.status === 'Unknown').length;
        const total_slots = venue.devices.map((device) => device.slots).reduce((a, b) => a + b, 0);
        const empty_slots = venue.devices
          .map((device) => device.active_slots)
          .reduce((a, b) => a + b, 0);
        let full_slots: number | undefined = total_slots - empty_slots;

        if (unknown > 0) {
          online = undefined;
          full_slots = undefined;
        }

        return {
          ...venue,
          online,
          total_slots,
          full_slots,
        };
      });

    setApiItems(filteredVenues);
  });

  const fetchApi = async () => {
    await api();
  };

  const filterTable = createFilter<VenueWithDevices>(query, VENUE_FILTER_CONFIGS);

  const filteredItems = apiItems.filter(filterTable);

  return {
    fetchApi,
    query,
    setQuery,
    propertyFilteringOptions,
    filteredItems,
    loading,
    error,
  };
}

export function useVenues() {
  const [apiItems, setApiItems] = useState<VenueWithDevices[]>([]);
  const { query, setQuery } = useVenueState();

  return useVenueFilterState(apiItems, setApiItems, query, setQuery);
}

export function useVenuesWithUrl() {
  const [apiItems, setApiItems] = useState<VenueWithDevices[]>([]);
  const { query, setQuery } = useUrlFilterState();

  return useVenueFilterState(apiItems, setApiItems, query, setQuery);
}

export type VenueFilterValues = {
  venues: VenueWithDevices[];
  venueTypes: string[];
  min_devices: number;
  max_devices: number;
  min_slots: number;
  max_slots: number;
};

export function getFilterValues(items: VenueWithDevices[]): VenueFilterValues {
  const venues: VenueWithDevices[] = [];
  const venueNames = new Set<string>();
  const venueTypes: string[] = [];
  const venueTypeNames = new Set<string>();
  let min_devices = Infinity;
  let max_devices = -Infinity;
  let min_slots = Infinity;
  let max_slots = -Infinity;

  for (const item of items) {
    min_devices = Math.min(min_devices, item.devices.length);
    max_devices = Math.max(max_devices, item.devices.length);
    min_slots = Math.min(min_slots, item.total_slots);
    max_slots = Math.max(max_slots, item.total_slots);

    if (!venueNames.has(item.venue_id)) {
      venues.push(item);
    }
    venueNames.add(item.venue_id);

    if (!venueTypeNames.has(item.type ?? 'Uncategorized')) {
      venueTypes.push(item.type ?? 'Uncategorized');
    }
    venueTypeNames.add(item.type ?? 'Uncategorized');
  }
  venues.sort((a, b) => a.name.localeCompare(b.name));
  venueTypes.sort((a, b) => a.localeCompare(b));

  return {
    venues,
    venueTypes,
    min_devices,
    max_devices,
    min_slots,
    max_slots,
  };
}
