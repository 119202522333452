import {
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  Container,
  Box,
  ColumnLayout,
  Spinner,
  StatusIndicator,
  SpaceBetween,
  DateRangePickerProps,
} from '@cloudscape-design/components';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';
import { useVenue } from '../../common/venue';
import { Venue } from '../../common/types';
import { ProvidedOrderTable } from '../../orders/table';
import { useUrlFilterState, useUrlPageState, useUrlRangeState } from '../../common/filter';
import { getVenueOrders, getVenueOrdersMeta } from '../../common/api/venues';

import { VenueOrderTable } from './orders-table';
import { VenueDeviceTable } from './devices-table';
import { VenueReport } from './venue-report';

export type VenueDetailsProps = {
  venue: Venue;
};

export function VenueDetails(props: VenueDetailsProps) {
  const { venue } = props;

  let logo = venue.logo;

  if (logo === null || logo === undefined || logo.trim() === '') {
    logo = 'https://cdn.aiescape.io/zappy-banner.jpg';
  }

  const logoImage = <img alt={`Logo for ${venue.name}`} src={logo} style={{ width: '100%' }} />;

  return (
    <Container
      header={<Header>{venue.name}</Header>}
      media={{
        content: logoImage,
        position: 'top',
      }}
      fitHeight
    >
      <SpaceBetween direction="vertical" size="s">
        <ColumnLayout columns={2} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Type</Box>
            <Box variant="p">{venue.type ?? 'Uncategorized'}</Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Address</Box>
            <Box variant="p">{venue.address}</Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Hours</Box>
            <Box variant="p">
              {venue.open_time && venue.open_time.trim().length > 0 ? venue.open_time : 'Unknown'}
            </Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Currency</Box>
            <Box variant="p">{venue.currency}</Box>
          </div>
        </ColumnLayout>
        {venue.description && venue.description.trim().length > 0 ? (
          <ColumnLayout columns={1} variant="text-grid">
            <div>
              <Box variant="awsui-key-label">Description</Box>
              <Box variant="p">{venue.description}</Box>
            </div>
          </ColumnLayout>
        ) : null}
      </SpaceBetween>
    </Container>
  );
}

export default function VenuePage() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const params = useParams();
  // Venue UUID
  const venueId = params.venueId ?? '';

  const { venue, loading, error, fetchApi } = useVenue(venueId);

  const { query, setQuery } = useUrlFilterState();
  const { range, setRange } = useUrlRangeState();
  const [page, setPage] = useUrlPageState();

  useEffect(() => {
    fetchApi();
  }, [venueId]);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Venues', href: '/venues' },
            {
              text: venue ? venue.name : loading ? '' : '',
              href: '',
            },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout
          header={
            <Header variant="h1">
              <span
                style={{
                  color: '#d5de24',
                }}
              >
                {venue ? `Zappy @ ${venue.name}` : loading ? ' ' : ' '}
              </span>
            </Header>
          }
        >
          <Grid
            gridDefinition={[
              { colspan: { default: 12, s: 6 } },
              { colspan: { default: 12, s: 6 } },
              { colspan: { default: 12, s: 12 } },
              // { colspan: { default: 12, s: 6 } },
            ]}
          >
            {error ? (
              <Container header={<Header>Error</Header>} fitHeight>
                <StatusIndicator type="error">{error}</StatusIndicator>
              </Container>
            ) : venue ? (
              <VenueDetails venue={venue!} />
            ) : (
              <Container header={<Header>Loading</Header>} fitHeight>
                <Spinner size="large" />
              </Container>
            )}
            {/* <VenueReport venueId={venueId} /> */}
            <VenueDeviceTable venueId={venueId} />
            <ProvidedOrderTable
              key={venueId}
              getMetaQuery={async () => {
                return await getVenueOrdersMeta(venueId);
              }}
              getOrdersQuery={async (
                query: PropertyFilterQuery,
                page: number,
                pageSize: number,
                range: DateRangePickerProps.Value | null,
                now: Date,
              ) => {
                return await getVenueOrders(venueId, query, page, pageSize, range, now);
              }}
              page={page}
              pageSize={10}
              query={query}
              range={range}
              setPage={setPage}
              setQuery={setQuery}
              setRange={setRange}
              skipColumns={['name']}
            />
            {/* <VenueOrderTable venueId={venueId} /> */}
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
