import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';

import UserContext from '../common/user';

export default function Home() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    navigate('/dashboard');
  }, [user]);

  return null;
}
