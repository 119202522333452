import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Header,
  SpaceBetween,
  Button,
  Table,
  Pagination,
  DateRangePickerProps,
} from '@cloudscape-design/components';
import { ReactNode, useMemo } from 'react';

import {
  DeviceWithOrders,
  DeviceWithUptime,
  Order,
  Venue,
  VenueWithOrders,
  VenueWithOrdersAndStats,
} from '../common/types';
import { getHeaderCounterText } from '../common/full-header';
import { VenueLink } from '../venues/link';
import { exportExcel } from '../common/excel';
import { rangeStartEnd } from '../common/filter';
import { computeStats } from '../common/stats';

function isUndefinedRps(rps: number | undefined) {
  return rps === undefined || isNaN(rps) || !isFinite(rps);
}

export function groupOrdersByVenue(orders: Order[]): VenueWithOrders[] {
  const venues = new Map<string, Order[]>();
  const venueMap = new Map<string, Venue>();
  orders.forEach((order) => {
    const venue = order.from_venue.name;
    venueMap.set(venue, order.from_venue);

    if (venues.has(venue)) {
      venues.get(venue)?.push(order);
    } else {
      venues.set(venue, [order]);
    }
  });
  const venueWithOrders: VenueWithOrders[] = [];
  venues.forEach((orders, venue) => {
    venueWithOrders.push({
      venue,
      orders,
      revenue: orders.reduce((total, order) => total + parseFloat(order.settlement_amount), 0),
      venueData: venueMap.get(venue) as Venue,
    });
  });

  return venueWithOrders;
}

export type VenueTableProps = {
  fetchApi: () => void;
  orders: Order[];
  devices: DeviceWithOrders[];
  range: DateRangePickerProps.Value | null;
  loading: boolean;
  error: string | null;
  itemFilter?: ReactNode;
  actions?: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
  pageSize?: number;
};

export function VenueTable(props: VenueTableProps) {
  const pageSize = props.pageSize ?? 15;
  const resourceName = 'Venue';

  const { fetchApi, orders, devices, loading, error } = props;

  const { start, end } = useMemo(
    () => (props.range ? rangeStartEnd(props.range) : { start: new Date(), end: new Date() }),
    [props.range],
  );

  const filteredItems = useMemo(() => {
    const itemsByVenue = groupOrdersByVenue(orders);
    const deviceMap = new Map<string, DeviceWithOrders>();

    for (const device of devices) {
      deviceMap.set(device.uuid, device);
    }

    const venueDevices = new Map<string, Set<string>>();

    // TODO there's a bug here when devices have belonged to different venues over time.
    // TODO need to have multiple different views of each device based on the venue.
    for (const device of devices) {
      for (const order of device.orders) {
        if (!venueDevices.has(order.from_venue.name)) {
          venueDevices.set(order.from_venue.name, new Set());
        }
        venueDevices.get(order.from_venue.name)?.add(device.uuid);
      }
    }

    const filteredItems: VenueWithOrdersAndStats[] = [];

    for (const venue of itemsByVenue) {
      const vDevices = Array.from(venueDevices.get(venue.venue) ?? []);
      const stats = computeStats(
        vDevices.map((uuid) => deviceMap.get(uuid)) as DeviceWithOrders[],
        start,
        end,
      );
      // const venueUptimes = devices
      //   .map((uuid) => uptimeMap.get(uuid))
      //   .filter((uptime) => uptime !== undefined) as DeviceWithUptime[];
      // const stats = computeUptimeStats(venueUptimes);
      const venueAvgRps = stats.reduce((total, device) => total + device.rps, 0) / stats.length;
      const venueAvgTps = stats.reduce((total, device) => total + device.tps, 0) / stats.length;
      filteredItems.push({
        ...venue,
        rps: venueAvgRps,
        tps: venueAvgTps,
      });
    }

    return filteredItems;
  }, [devices, orders]);

  const { items, collectionProps, paginationProps } = useCollection(filteredItems, {
    pagination: { pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'revenue',
          sortingComparator(a, b) {
            const x = a.revenue;
            const y = b.revenue;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        isDescending: true,
      },
    },
  });

  return (
    <Table
      {...collectionProps}
      {...paginationProps}
      columnDefinitions={[
        {
          id: 'name',
          header: 'Name',
          cell: (e) => <VenueLink venue={e.venueData} />,
          sortingField: 'name',
          sortingComparator(a, b) {
            const x = a.venue;
            const y = b.venue;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'type',
          header: 'Type',
          cell: (e) => e.venueData.type ?? 'Uncategorized',
          sortingField: 'type',
          sortingComparator(a, b) {
            const x = a.venueData.type ?? 'Uncategorized';
            const y = b.venueData.type ?? 'Uncategorized';

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'revenue',
          header: 'Revenue',
          cell: (e) => `$${e.revenue.toLocaleString()} USD`,
          sortingField: 'revenue',
          sortingComparator(a, b) {
            const x = a.revenue;
            const y = b.revenue;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'orders',
          header: 'Orders',
          cell: (e) => `${e.orders.length.toLocaleString()}`,
          sortingField: 'orders',
          sortingComparator(a, b) {
            const x = a.orders.length;
            const y = b.orders.length;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'rpm',
          header: 'RPM',
          cell: (e) => (isUndefinedRps(e.rps) ? 'N/A' : `$${e.rps.toFixed(2)}`),
          sortingField: 'rpm',
          sortingComparator(a, b) {
            const x = a.rps;
            const y = b.rps;

            if (isUndefinedRps(x) && isUndefinedRps(y)) {
              return 0;
            }

            if (isUndefinedRps(x)) {
              return -1;
            }

            if (isUndefinedRps(y)) {
              return 1;
            }

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'tpm',
          header: 'TPM',
          cell: (e) => (isUndefinedRps(e.rps) ? 'N/A' : `${e.tps.toFixed(1)}`),
          sortingField: 'tpm',
          sortingComparator(a, b) {
            const x = a.tps;
            const y = b.tps;

            if (isUndefinedRps(x) && isUndefinedRps(y)) {
              return 0;
            }

            if (isUndefinedRps(x)) {
              return -1;
            }

            if (isUndefinedRps(y)) {
              return 1;
            }

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
      ]}
      empty={
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {error}
                </Box>
              </div>
              <Button onClick={fetchApi}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      }
      filter={props.itemFilter}
      header={
        <Header
          actions={
            props.actions ?? (
              <Button
                disabled={loading}
                iconName="download"
                onClick={() => {
                  exportExcel(
                    filteredItems
                      .map((venue) => ({
                        name: venue.venueData.name,
                        revenue: venue.revenue,
                        orders: venue.orders.length,
                        rps: isNaN(venue.rps) ? 'N/A' : venue.rps,
                        tps: isNaN(venue.tps) ? 'N/A' : venue.tps,
                        currency: venue.venueData.currency,
                        address: venue.venueData.address,
                      }))
                      .toSorted((a, b) => b.revenue - a.revenue),
                    'venue_revenue',
                  );
                }}
              />
            )
          }
          counter={getHeaderCounterText(filteredItems, collectionProps.selectedItems)}
          description={props.description}
        >
          {props.title ?? `${resourceName}s`}
        </Header>
      }
      items={items}
      loading={loading}
      loadingText={`Loading ${resourceName.toLowerCase()}s`}
      pagination={<Pagination {...paginationProps} disabled={loading} />}
      trackBy="id"
    />
  );
}
