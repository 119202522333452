import { useContext, useState } from 'react';
import {
  Container,
  Header,
  AppLayout,
  ContentLayout,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Grid,
  StatusIndicator,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';
import { UserUpdate } from '../../common/types';
import { useApiNoResponse, useRefreshApi } from '../../common/api';

export default function UpdateAccount() {
  const navigate = useNavigate();

  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [passwordErrorText, setPasswordErrorText] = useState<string>('');
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState<string>('');

  const [updateApi, error, loading] = useApiNoResponse<UserUpdate>('/user', 'POST');

  // TODO show error
  const [refreshApi, refreshError, refreshLoading] = useRefreshApi();

  const update = async () => {
    let formError = false;

    if (password) {
      if (password.length < 12) {
        setPasswordErrorText('Password must be at least 12 characters');
        formError = true;
      } else if (password.toUpperCase() === password) {
        setPasswordErrorText('Password must contain at least one lowercase letter');
        formError = true;
      } else if (password.toLowerCase() === password) {
        setPasswordErrorText('Password must contain at least one uppercase letter');
        formError = true;
      } else if (password.search(/[0-9]/) < 0) {
        setPasswordErrorText('Password must contain at least one number');
        formError = true;
      } else if (password.search(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/) < 0) {
        setPasswordErrorText('Password must contain at least one special character');
        formError = true;
      } else {
        setPasswordErrorText('');
      }

      if (!confirmPassword) {
        setConfirmPasswordErrorText('Confirm password is required');
        formError = true;
      } else if (password !== confirmPassword) {
        setConfirmPasswordErrorText('Passwords do not match');
        formError = true;
      } else {
        setConfirmPasswordErrorText('');
      }
    } else {
      setPasswordErrorText('');
      setConfirmPasswordErrorText('');
    }

    if (formError) {
      return;
    }

    const info: UserUpdate = {
      password: password ? password : undefined,
    };

    const response = await updateApi(info);

    if (!response.error) {
      const result = await refreshApi();

      if (!result.error) {
        navigate(`/account`);
      }
    }
  };

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Account', href: '/account' },
            { text: 'Update', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Update Your Account</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 6,
                  s: 4,
                },
              },
            ]}
          >
            <form onSubmit={(event) => event.preventDefault()}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="link"
                      onClick={() => {
                        navigate(`/account`);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button loading={loading} variant="primary" onClick={update}>
                      Update
                    </Button>
                  </SpaceBetween>
                }
                errorText={error}
              >
                <SpaceBetween size="l">
                  <Container header={<Header variant="h2">Update Account Details</Header>}>
                    <SpaceBetween size="l">
                      <FormField
                        description="Enter a new password for your account. Your password must be at least 12 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                        errorText={passwordErrorText}
                        label="Password"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Enter password..."
                          type="password"
                          value={password}
                          onChange={({ detail: { value } }) => setPassword(value)}
                        />
                      </FormField>
                      <FormField
                        description="Re-enter your new password to confirm."
                        errorText={confirmPasswordErrorText}
                        label="Confirm Password"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Re-enter new password..."
                          type="password"
                          value={confirmPassword}
                          onChange={({ detail: { value } }) => setConfirmPassword(value)}
                        />
                      </FormField>
                      {loading ? (
                        <StatusIndicator type="loading">Updating account...</StatusIndicator>
                      ) : (
                        <div />
                      )}
                    </SpaceBetween>
                  </Container>
                </SpaceBetween>
              </Form>
            </form>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
