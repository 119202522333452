import { useEffect } from 'react';

import { DeviceFilter, DeviceTable } from '../../devices/table';
import { useDevices } from '../../devices/device-filtering';

export type VenueDeviceTableProps = {
  venueId: string;
};

export function VenueDeviceTable(props: VenueDeviceTableProps) {
  const { venueId } = props;

  const { fetchApi, query, setQuery, propertyFilteringOptions, filteredItems, loading, error } =
    useDevices(`/venues/${venueId}/devices`);

  useEffect(() => {
    fetchApi();
  }, [venueId]);

  const itemFilter = (
    <DeviceFilter
      loading={loading}
      propertyFilteringOptions={propertyFilteringOptions}
      query={query}
      setQuery={setQuery}
      skipColumns={['venue']}
    />
  );

  return (
    <DeviceTable
      error={error}
      fetchApi={fetchApi}
      filteredItems={filteredItems}
      itemFilter={itemFilter}
      loading={loading}
      pageSize={20}
      skipColumns={['venue']}
    />
  );
}
