import { useState } from 'react';

import { useApiNoBody } from '../common/api';

import { Venue } from './types';

export const IGNORE_VENUES = [
  'defaultShop',
  '深圳市睿联通信技术有限公司',
  'Shenzhen Ruilian Communication Technology Co., Ltd',
  'Zappy Demo',
  'Zappy Demo Account',
  'New Zappy Demo Account',
  'BJDL',
  'Zappy Office',
  'Zappy Demo Account USA',
  'zappytest shop1',
  'Canada Demo Account',
];

export function useVenue(venueId: string) {
  const [venue, setVenue] = useState<Venue | null>(null);
  const [api, error, loading] = useApiNoBody<Venue>(`/venues/${venueId}`, 'GET', (items) => {
    setVenue(items);
  });

  const fetchApi = async () => {
    await api();
  };

  return {
    venue,
    fetchApi,
    loading,
    error,
  };
}
