import { useContext, useEffect, useState } from 'react';
import {
  Box,
  AppLayout,
  Cards,
  Button,
  SpaceBetween,
  StatusIndicator,
  Pagination,
} from '@cloudscape-design/components';
import Link from '@cloudscape-design/components/link';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import { FullPageHeader, getHeaderCounterText } from '../common/full-header';
import { useApiNoBody, useApiNoBodyNoResponse } from '../common/api';

import 'react-lazy-load-image-component/src/effects/blur.css';
import { ChatSession } from '../common/chat/types';
import { truncateWithEllipsis } from '../common/formatting';

export default function Chats() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const navigate = useNavigate();
  const pageSize = 12;
  const [chats, setChats] = useState<ChatSession[]>([]);

  const [getApi, error, loading] = useApiNoBody<ChatSession[]>('/chat', 'GET', (data) =>
    setChats(data.toReversed()),
  );
  const [deleteApi] = useApiNoBodyNoResponse('/chat', 'DELETE');

  useEffect(() => {
    getApi();
  }, []);

  const { items, collectionProps, paginationProps } = useCollection(chats, {
    pagination: { pageSize },
    selection: {},
  });

  const deleteSelected = async () => {
    let deleted = false;
    let deleteError = undefined;

    for (const item of collectionProps.selectedItems ?? []) {
      const result = await deleteApi(undefined, item.uuid);

      if (result.error) {
        deleteError = result.error;
        break;
      } else {
        deleted = true;
      }
    }

    // TODO display deleteError
    if (deleted) {
      getApi();
    }
  };

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'History', href: '' }]} />}
      className="app-layout"
      content={
        <Cards
          {...collectionProps}
          cardDefinition={{
            header: (item) => (
              <SpaceBetween direction="vertical" size="xs">
                <Link
                  fontSize="heading-m"
                  href={`/chat/${item.uuid}`}
                  variant="secondary"
                  onFollow={(e) => {
                    e.preventDefault();
                    navigate(`/chat/${item.uuid}`);
                  }}
                >
                  {/* TODO more friendly title */}
                  {item.initial_message ? truncateWithEllipsis(item.initial_message, 50) : 'Chat'}
                </Link>
              </SpaceBetween>
            ),
            sections: [
              {
                id: 'created_at',
                header: 'Created',
                content: (item) => (
                  <Box variant="small">{new Date(item.created_at + 'Z').toLocaleString()}</Box>
                ),
              },
              {
                id: 'status',
                header: 'Status',
                content: (item) => (
                  <StatusIndicator type={item.run_id ? 'in-progress' : 'success'}>
                    {item.run_id ? 'Responding' : 'Available'}
                  </StatusIndicator>
                ),
              },
            ],
          }}
          empty={
            <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
              {error ? (
                <SpaceBetween size="xxs">
                  <div>
                    <b>Error retrieving Chats</b>
                    <Box color="inherit" variant="p">
                      {error}
                    </Box>
                  </div>
                  <Button onClick={() => getApi()}>Retry</Button>
                </SpaceBetween>
              ) : (
                <SpaceBetween size="xxs">
                  <div>
                    <b>No Chats</b>
                    <Box color="inherit" variant="p">
                      No Chats found.
                    </Box>
                  </div>
                  <Button
                    onClick={() => {
                      navigate('/chat');
                    }}
                  >
                    Create Chat
                  </Button>
                </SpaceBetween>
              )}
            </Box>
          }
          header={
            <FullPageHeader
              counter={getHeaderCounterText(chats, collectionProps.selectedItems)}
              selectedItems={collectionProps.selectedItems ?? []}
              onDeleteClick={deleteSelected}
            />
          }
          items={items}
          loading={loading}
          pagination={<Pagination {...paginationProps} disabled={loading} />}
          // TODO use PropertyFilter
          // https://cloudscape.design/components/property-filter/?tabId=playground
          loadingText="Loading chats"
          selectionType="multi"
          variant="full-page"
          stickyHeader
        />
      }
      contentType="cards"
      headerSelector=".top-navigation"
      maxContentWidth={Number.MAX_VALUE}
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
