import { StatusIndicator } from '@cloudscape-design/components';
import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';

import { DeviceWithVenue } from './types';

export type DeviceStatus = 'Online' | 'Offline' | 'Unknown';

export type DeviceStatusIconProps = {
  status: string;
};

export function DeviceStatusIcon(props: DeviceStatusIconProps) {
  const { status } = props;

  // 'error' | 'warning' | 'success' | 'info' | 'stopped' | 'pending' | 'in-progress' | 'loading';
  switch (status) {
    case 'Unknown':
      return <StatusIndicator type="pending">{status}</StatusIndicator>;
    case 'Online':
      return <StatusIndicator type="success">{status}</StatusIndicator>;
    case 'Offline':
      return <StatusIndicator type="warning">{status}</StatusIndicator>;
    default:
      return <StatusIndicator type="stopped">{status}</StatusIndicator>;
  }
}

function filterOperator(a: boolean, b: boolean, operator: 'and' | 'or') {
  return operator === 'and' ? a && b : a || b;
}

export function filterDevices(query: PropertyFilterQuery) {
  function filterDevice(device: DeviceWithVenue) {
    let matches = query.operation === 'and';

    for (const token of query.tokens) {
      if (token.propertyKey === 'venue') {
        if (token.operator === '=') {
          matches = filterOperator(matches, device.venue.name === token.value, query.operation);
        } else if (token.operator === '!=') {
          matches = filterOperator(matches, device.venue.name !== token.value, query.operation);
        } else if (token.operator === ':') {
          matches = filterOperator(
            matches,
            device.venue.name.toLowerCase().includes(token.value.toLowerCase()),
            query.operation,
          );
        } else if (token.operator === '!:') {
          matches = filterOperator(
            matches,
            !device.venue.name.toLowerCase().includes(token.value.toLowerCase()),
            query.operation,
          );
        }
      } else if (token.propertyKey === 'device') {
        if (token.operator === '=') {
          matches = filterOperator(matches, device.device_id === token.value, query.operation);
        } else if (token.operator === '!=') {
          matches = filterOperator(matches, device.device_id !== token.value, query.operation);
        }
      } else if (token.propertyKey === 'status') {
        if (token.operator === '=') {
          matches = filterOperator(matches, device.status === token.value, query.operation);
        } else if (token.operator === '!=') {
          matches = filterOperator(matches, device.status !== token.value, query.operation);
        }
      } else if (token.propertyKey === 'source') {
        if (token.operator === '=') {
          matches = filterOperator(
            matches,
            device.vendor.name === 'Relink' ? token.value === 'Uber' : token.value === 'Kiosk',
            query.operation,
          );
        } else if (token.operator === '!=') {
          matches = filterOperator(
            matches,
            device.vendor.name === 'Relink' ? token.value !== 'Uber' : token.value !== 'Kiosk',
            query.operation,
          );
        }
      } else if (token.propertyKey === 'slots') {
        if (token.operator === '=') {
          matches = filterOperator(
            matches,
            device.slots === parseInt(token.value),
            query.operation,
          );
        } else if (token.operator === '!=') {
          matches = filterOperator(
            matches,
            device.slots !== parseInt(token.value),
            query.operation,
          );
        } else if (token.operator === '>=') {
          matches = filterOperator(matches, device.slots >= parseInt(token.value), query.operation);
        } else if (token.operator === '<=') {
          matches = filterOperator(matches, device.slots <= parseInt(token.value), query.operation);
        } else if (token.operator === '>') {
          matches = filterOperator(matches, device.slots > parseInt(token.value), query.operation);
        } else if (token.operator === '<') {
          matches = filterOperator(matches, device.slots < parseInt(token.value), query.operation);
        }
      } else if (token.propertyKey === 'active_slots') {
        if (token.operator === '=') {
          matches = filterOperator(
            matches,
            device.active_slots === parseInt(token.value),
            query.operation,
          );
        } else if (token.operator === '!=') {
          matches = filterOperator(
            matches,
            device.active_slots !== parseInt(token.value),
            query.operation,
          );
        } else if (token.operator === '>=') {
          matches = filterOperator(
            matches,
            device.active_slots >= parseInt(token.value),
            query.operation,
          );
        } else if (token.operator === '<=') {
          matches = filterOperator(
            matches,
            device.active_slots <= parseInt(token.value),
            query.operation,
          );
        } else if (token.operator === '>') {
          matches = filterOperator(
            matches,
            device.active_slots > parseInt(token.value),
            query.operation,
          );
        } else if (token.operator === '<') {
          matches = filterOperator(
            matches,
            device.active_slots < parseInt(token.value),
            query.operation,
          );
        }
      }
    }

    return matches;
  }

  return filterDevice;
}
