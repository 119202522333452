import { useContext, useEffect, useState } from 'react';
import {
  Container,
  Header,
  AppLayout,
  ContentLayout,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Grid,
  Box,
  Link,
} from '@cloudscape-design/components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import jwt from 'jwt-decode';

import { Breadcrumbs } from '../../common/navigation';
import { UserResponse, UserSignup, UserTokenInfo } from '../../common/types';
import UserContext from '../../common/user';
import { useApi } from '../../common/api';

export default function Signup() {
  const navigate = useNavigate();

  const [queryParameters] = useSearchParams();

  const email = queryParameters.get('email') ?? undefined;
  const code = queryParameters.get('code') ?? undefined;

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [passwordErrorText, setPasswordErrorText] = useState<string>('');
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState<string>('');

  useEffect(() => {
    if (!email || !code) {
      navigate('/signup');
    }
  }, []);

  const { setUser } = useContext(UserContext);

  const [signupApi, error, loading] = useApi<UserSignup, UserResponse>(
    '/auth/signup',
    'POST',
    (response) => {
      const token = response.token;
      const refresh_token = response.refresh_token;
      localStorage.setItem('token', token);
      localStorage.setItem('refresh_token', refresh_token);
      setUser(token ? (jwt(token) as UserTokenInfo) : null);

      navigate(`/`);
    },
  );

  const signup = async () => {
    let formError = false;

    if (!password) {
      setPasswordErrorText('Password is required');
      formError = true;
    } else if (password.length < 12) {
      setPasswordErrorText('Password must be at least 12 characters');
      formError = true;
    } else if (password.toUpperCase() === password) {
      setPasswordErrorText('Password must contain at least one lowercase letter');
      formError = true;
    } else if (password.toLowerCase() === password) {
      setPasswordErrorText('Password must contain at least one uppercase letter');
      formError = true;
    } else if (password.search(/[0-9]/) < 0) {
      setPasswordErrorText('Password must contain at least one number');
      formError = true;
    } else if (password.search(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/) < 0) {
      setPasswordErrorText('Password must contain at least one special character');
      formError = true;
    } else {
      setPasswordErrorText('');
    }

    if (!confirmPassword) {
      setConfirmPasswordErrorText('Confirm password is required');
      formError = true;
    } else if (password !== confirmPassword) {
      setConfirmPasswordErrorText('Passwords do not match');
      formError = true;
    } else {
      setConfirmPasswordErrorText('');
    }

    if (formError) {
      return;
    }

    const info: UserSignup = {
      email,
      password,
      code,
    };

    await signupApi(info);
  };

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Sign Up', href: '/signup' },
            { text: 'Confirmation', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout
          header={<Header variant="h1">Sign Up for the Zappy Management Portal</Header>}
        >
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  xs: 6,
                },
              },
            ]}
          >
            <form onSubmit={(event) => event.preventDefault()}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="link"
                      onClick={() => {
                        navigate(`/`);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button disabled={loading} loading={loading} variant="primary" onClick={signup}>
                      Sign Up
                    </Button>
                  </SpaceBetween>
                }
                errorText={error}
              >
                <SpaceBetween size="l">
                  <Container header={<Header variant="h2">Create an Account</Header>}>
                    <Box variant="p">
                      By creating an account, you agree to our{' '}
                      <Link href="https://needzappy.com/terms-conditions/" target="_blank" external>
                        Terms & Conditions
                      </Link>{' '}
                      and{' '}
                      <Link href="https://needzappy.com/privacy-policy-2/" target="_blank" external>
                        Privacy Policy
                      </Link>
                      .
                    </Box>
                    <SpaceBetween size="l">
                      <FormField description="Your email address." label="Email">
                        <Input disabled={loading} type="email" value={email ?? ''} readOnly />
                      </FormField>

                      <FormField
                        description="Enter a password for your account. Your password must be at least 12 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                        errorText={passwordErrorText}
                        label="Password"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Enter password..."
                          type="password"
                          value={password}
                          onChange={({ detail: { value } }) => setPassword(value)}
                        />
                      </FormField>
                      <FormField
                        description="Re-enter your password to confirm."
                        errorText={confirmPasswordErrorText}
                        label="Confirm Password"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Re-enter password..."
                          type="password"
                          value={confirmPassword}
                          onChange={({ detail: { value } }) => setConfirmPassword(value)}
                        />
                      </FormField>
                    </SpaceBetween>
                  </Container>
                </SpaceBetween>
              </Form>
            </form>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigationHide
      toolsHide
    />
  );
}
