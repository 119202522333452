import {
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  Container,
  Box,
  ColumnLayout,
  Spinner,
  StatusIndicator,
  SpaceBetween,
  DateRangePickerProps,
} from '@cloudscape-design/components';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';
import { useDevice } from '../../common/device';
import { DeviceWithVenue } from '../../common/types';
import { DeviceStatusIcon } from '../../common/devices';
import { VenueLink } from '../../venues/link';
import { PercentStatus } from '../../common/percent-status';
import { useUrlFilterState, useUrlPageState, useUrlRangeState } from '../../common/filter';
import { OrderFilter, ProvidedOrderTable } from '../../orders/table';
import { getDeviceOrders, getDeviceOrdersMeta } from '../../common/api/devices';

import { DeviceOrderTable } from './orders-table';
import { DeviceHeartbeatGraph } from './heartbeat-graph';
import { DeviceReport } from './device-report';

export type DeviceImageProps = {
  device: DeviceWithVenue;
};

export function DeviceImage(props: DeviceImageProps) {
  const { device } = props;

  // TODO get zappy promo image for each slotted device
  let logo = null;

  if (logo === null || logo === undefined) {
    logo = 'https://needzappy.com/wp-content/uploads/2023/09/duozappy-1024x1024.png';
  }

  // 5, 6, 8, 12, 24, 48
  switch (device.slots) {
    case 6:
      // TODO find correct image
      logo = 'https://cdn.aiescape.io/zappy-device-6.jpg';
      break;
    case 12:
      // correct image
      logo = 'https://cdn.aiescape.io/zappy-devices-12.jpg';
      break;
    case 24:
      // correct image
      logo = 'https://cdn.aiescape.io/zappy-devices-24.jpg';
      break;
    case 48:
      // correct image
      logo = 'https://cdn.aiescape.io/zappy-devices-48.jpg';
      break;
    case 96:
      // correct image (rendering)
      logo = 'https://needzappy.com/wp-content/uploads/2023/09/kiosk3-1024x1024.png';
      break;
    default:
      logo = 'https://cdn.aiescape.io/zappy-devices-12.jpg';
      break;
  }

  return <img alt={`Logo for ${device.device_id}`} src={logo} style={{ width: '100%' }} />;
}

export type DeviceDetailsProps = {
  device: DeviceWithVenue;
};

export function DeviceDetails(props: DeviceDetailsProps) {
  const { device } = props;

  return (
    <Container
      header={<Header>{device.device_id}</Header>}
      media={{
        content: <DeviceImage device={device} />,
        position: 'side',
        width: '60%',
      }}
      fitHeight
    >
      <ColumnLayout columns={2} variant="text-grid">
        <div>
          <Box variant="awsui-key-label">Venue</Box>
          <VenueLink venue={device.venue} />
        </div>
        <div>
          <Box variant="awsui-key-label">Position</Box>
          <Box variant="p">{device.kiosk?.position_description ?? 'N/A'}</Box>
        </div>
        <div>
          <Box variant="awsui-key-label">Device Status</Box>
          <DeviceStatusIcon status={device.status} />
        </div>
        <div>
          <Box variant="awsui-key-label">Installation Date</Box>
          <Box variant="p">{device.kiosk?.install_date ?? 'N/A'}</Box>
        </div>
        <div>
          <Box variant="awsui-key-label">Uninstall Date</Box>
          <Box variant="p">{device.kiosk?.uninstall_date ?? 'N/A'}</Box>
        </div>
        <div>
          <Box variant="awsui-key-label">Full Slots</Box>
          <PercentStatus
            max_percent={50}
            min_percent={10}
            total={device.total_slots}
            value={device.full_slots}
          />
        </div>
        <div>
          <Box variant="awsui-key-label">Source</Box>
          <Box variant="p">{device.vendor.name === 'Relink' ? 'Uber' : 'Kiosk'}</Box>
        </div>
      </ColumnLayout>
    </Container>
  );
}

export default function DevicePage() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const params = useParams();
  // Device UUID
  const deviceId = params.deviceId ?? '';

  const { device, loading, error, fetchApi } = useDevice(deviceId);

  const { query, setQuery } = useUrlFilterState();
  const { range, setRange } = useUrlRangeState();
  const [page, setPage] = useUrlPageState();

  useEffect(() => {
    fetchApi();
  }, [deviceId]);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Devices', href: '/devices' },
            {
              text: device ? device.device_id : loading ? ' ' : ' ',
              href: '',
            },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout
          header={
            <Header variant="h1">
              <span
                style={{
                  color: '#d5de24',
                }}
              >
                {device
                  ? `Zappy Device ${device.device_id}`
                  : loading
                  ? 'Loading Device...'
                  : 'Device Error'}
              </span>
            </Header>
          }
        >
          <Grid
            gridDefinition={[
              { colspan: { default: 12, s: 6 } },
              { colspan: { default: 12, s: 6 } },
              { colspan: { default: 12, s: 12 } },
              // { colspan: { default: 12, s: 6 } },
            ]}
          >
            {error ? (
              <Container header={<Header>Error</Header>} fitHeight>
                <StatusIndicator type="error">{error}</StatusIndicator>
              </Container>
            ) : device ? (
              <DeviceDetails device={device!} />
            ) : (
              <Container header={<Header>Loading</Header>} fitHeight>
                <Spinner size="large" />
              </Container>
            )}
            {/* <DeviceReport deviceId={deviceId} /> */}
            <DeviceHeartbeatGraph deviceId={deviceId} />
            <ProvidedOrderTable
              key={deviceId}
              getMetaQuery={async () => {
                return await getDeviceOrdersMeta(deviceId);
              }}
              getOrdersQuery={async (
                query: PropertyFilterQuery,
                page: number,
                pageSize: number,
                range: DateRangePickerProps.Value | null,
                now: Date,
              ) => {
                return await getDeviceOrders(deviceId, query, page, pageSize, range, now);
              }}
              page={page}
              pageSize={10}
              query={query}
              range={range}
              setPage={setPage}
              setQuery={setQuery}
              setRange={setRange}
              skipColumns={['device_id']}
            />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
