import { PropertyFilterOption } from '@cloudscape-design/collection-hooks';
import { PropertyFilterProps } from '@cloudscape-design/components';

import { OrderMeta } from '../types';

import {
  MATCH_FILTER_OPERATORS,
  NUMBER_FILTER_OPERATORS,
  TEXT_FILTER_OPERATORS,
} from './operators';

export function buildFiltering(meta: OrderMeta) {
  const filteringProperties: PropertyFilterProps.FilteringProperty[] = [
    {
      key: 'device_id',
      operators: MATCH_FILTER_OPERATORS,
      propertyLabel: 'Device',
      groupValuesLabel: 'Devices',
    },
    // {
    //   key: 'payment_status',
    //   operators: MATCH_FILTER_OPERATORS,
    //   propertyLabel: 'Payment Status',
    //   groupValuesLabel: 'Payment Statuses',
    // },
    {
      key: 'rent_status',
      operators: MATCH_FILTER_OPERATORS,
      propertyLabel: 'Status',
      groupValuesLabel: 'Statuses',
    },
    {
      key: 'settlement_amount',
      operators: NUMBER_FILTER_OPERATORS,
      propertyLabel: 'Revenue',
      groupValuesLabel: 'Revenue',
    },
    {
      key: 'name',
      operators: TEXT_FILTER_OPERATORS,
      propertyLabel: 'Venue',
      groupValuesLabel: 'Venue',
    },
  ];
  const propertyFilteringOptions: PropertyFilterOption[] = [];

  if (meta.devices) {
    for (const val of meta.devices) {
      propertyFilteringOptions.push({
        propertyKey: 'device_id',
        value: val,
      });
    }
  }

  // for (const val of meta.payment_statuses) {
  //   propertyFilteringOptions.push({
  //     propertyKey: 'payment_status',
  //     value: val,
  //   });
  // }

  if (meta.rent_statuses) {
    for (const val of meta.rent_statuses) {
      propertyFilteringOptions.push({
        propertyKey: 'rent_status',
        value: val,
      });
    }
  }

  for (const val of [meta.revenue_min, meta.revenue_max]) {
    if (val !== undefined) {
      propertyFilteringOptions.push({
        propertyKey: 'settlement_amount',
        value: `${val}`,
      });
    }
  }

  if (meta.venues) {
    for (const val of meta.venues) {
      propertyFilteringOptions.push({
        propertyKey: 'name',
        value: val,
      });
    }
  }

  return {
    options: propertyFilteringOptions,
    properties: filteringProperties,
  };
}
