import { StatusIndicator } from '@cloudscape-design/components';

import { Device, Order, Venue } from './types';

export type PaymentStatus = 'Unpaid' | 'Paid' | 'Unknown';

export type RentalStatus = 'On Rental' | 'Revoked' | 'Returned' | 'Overtime' | 'Unknown';

export type RentalStatusIconProps = {
  status: string;
};

export function RentalStatusIcon(props: RentalStatusIconProps) {
  const { status } = props;

  // 'error' | 'warning' | 'success' | 'info' | 'stopped' | 'pending' | 'in-progress' | 'loading';
  switch (status) {
    case 'On Rental':
      return <StatusIndicator type="pending">{status}</StatusIndicator>;
    case 'Revoked':
      return <StatusIndicator type="error">{status}</StatusIndicator>;
    case 'Returned':
      return <StatusIndicator type="success">{status}</StatusIndicator>;
    case 'Overtime':
      return <StatusIndicator type="warning">{status}</StatusIndicator>;
    default:
      return <StatusIndicator type="stopped">{status}</StatusIndicator>;
  }
}

export type PaymentStatusIconProps = {
  status: string;
};

export function PaymentStatusIcon(props: PaymentStatusIconProps) {
  const { status } = props;

  // 'error' | 'warning' | 'success' | 'info' | 'stopped' | 'pending' | 'in-progress' | 'loading';
  switch (status) {
    case 'Unpaid':
      return <StatusIndicator type="error">{status}</StatusIndicator>;
    case 'Paid':
      return <StatusIndicator type="success">{status}</StatusIndicator>;
    default:
      return <StatusIndicator type="stopped">{status}</StatusIndicator>;
  }
}

export function getDuration(order: Order): number | null {
  if (order.returned_time === null) {
    return null;
  }

  const rentTime = new Date(order.rent_time + 'Z');
  const returnTime = new Date(order.returned_time + 'Z');
  const diff = returnTime.getTime() - rentTime.getTime();

  return diff;
}

export type OrderFilterValues = {
  payment_statuses: string[];
  rental_statuses: string[];
  venues: Venue[];
  devices: Device[];
  sources: string[];
  revenues: {
    min: number;
    max: number;
  };
  durations: {
    max: number;
  };
  venueTypes: string[];
};

export type OrderDevicesAndVenues = {
  devices: Device[];
  venues: Venue[];
};

export function getDevicesAndVenues(items: Order[]): OrderDevicesAndVenues {
  const devices: Device[] = [];
  const deviceNames = new Set<string>();
  const venues: Venue[] = [];
  const venueNames = new Set<string>();

  for (const item of items) {
    if (!deviceNames.has(item.device.device_id)) {
      devices.push(item.device);
    }

    if (!venueNames.has(item.from_venue.venue_id)) {
      venues.push(item.from_venue);
    }
    deviceNames.add(item.device.device_id);
    venueNames.add(item.from_venue.venue_id);
  }
  devices.sort((a, b) => a.device_id.localeCompare(b.device_id));
  venues.sort((a, b) => a.name.localeCompare(b.name));

  return {
    devices,
    venues,
  };
}

export function getFilterValues(items: Order[]): OrderFilterValues {
  const payment_statuses = new Set<string>();
  const rental_statuses = new Set<string>();
  const venues: Venue[] = [];
  const devices: Device[] = [];
  const deviceNames = new Set<string>();
  const venueNames = new Set<string>();
  const sources = new Set<string>();
  const venueTypes: string[] = [];
  const venueTypeNames = new Set<string>();
  let min_revenue = 0;
  let max_revenue = 0;
  let max_duration = 0;

  for (const item of items) {
    payment_statuses.add(item.payment_status);
    rental_statuses.add(item.rent_status);

    if (!deviceNames.has(item.device.device_id)) {
      devices.push(item.device);
    }

    if (!venueNames.has(item.from_venue.venue_id)) {
      venues.push(item.from_venue);
    }

    if (!venueTypeNames.has(item.from_venue.type ?? 'Uncategorized')) {
      venueTypes.push(item.from_venue.type ?? 'Uncategorized');
    }

    deviceNames.add(item.device.device_id);
    venueNames.add(item.from_venue.venue_id);
    venueTypeNames.add(item.from_venue.type ?? 'Uncategorized');
    sources.add(item.order_type === 'Uber' ? 'Uber' : 'Kiosk');
    const amt = parseFloat(item.settlement_amount);

    if (amt < min_revenue) {
      min_revenue = amt;
    }

    if (amt > max_revenue) {
      max_revenue = amt;
    }

    const diff = getDuration(item);

    if (diff !== null && diff > max_duration) {
      max_duration = diff;
    }
  }
  venues.sort((a, b) => a.name.localeCompare(b.name));
  devices.sort((a, b) => a.device_id.localeCompare(b.device_id));

  return {
    payment_statuses: Array.from(payment_statuses),
    rental_statuses: Array.from(rental_statuses),
    venues,
    devices,
    sources: Array.from(sources),
    revenues: {
      min: min_revenue,
      max: max_revenue,
    },
    durations: {
      max: max_duration,
    },
    venueTypes,
  };
}
