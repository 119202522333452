import ReactDOM from 'react-dom/client';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import enMessages from '@cloudscape-design/components/i18n/messages/all.en';
import queryString from 'query-string';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();
// {
//   queryCache: new QueryCache({
//     onError: (error, query) => {
//       const { title, description } = getErrorDetails(error);
//       // Report the error to Sentry
//       Sentry.captureException(error, {
//         tags: {
//           queryKey: `${query.queryKey}`,
//           title,
//           description,
//         },
//       });
//     },
//   }),
//   mutationCache: new MutationCache({
//     onError: (error) => {
//       const { title, description } = getErrorDetails(error);
//       // Report the error to Sentry
//       Sentry.captureException(error, {
//         tags: {
//           title,
//           description,
//         },
//       });
//     },
//   }),
// }

root.render(
  <BrowserRouter>
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: queryString.parse,
        objectToSearchString: queryString.stringify,
        removeDefaultsFromUrl: true,
      }}
    >
      <I18nProvider locale="en" messages={[enMessages]}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </I18nProvider>
    </QueryParamProvider>
  </BrowserRouter>,
);
