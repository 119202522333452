import {
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  SpaceBetween,
  Button,
  Container,
  DatePicker,
  Input,
  FormField,
  Alert,
  Box,
  Select,
  Spinner,
  DateRangePickerProps,
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import { formatBig, formatFinancial } from '../common/formatting';
import { ProvidedOrderTable } from '../orders/table';
import { VenueTable } from '../report/venue';
import { useDevicesFromOrders } from '../common/stats';
import { useLocalFilterState, useLocalPageState, useLocalRangeState } from '../common/filter';
import { ZappyHeader } from '../common/header';

import { useDashboard } from './dashboard';
import { CenteredComparison } from './reporting';
import { DashboardMetrics } from './metrics';
import { DashboardFilter } from './filter';

export default function Dashboard() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const navigate = useNavigate();

  const { fetchApi, date, setDate, days, setDays, report, loading, error } = useDashboard();
  // const orders = report?.report_orders || [];
  // const { filteredItems: devices } = useDevicesFromOrders(orders);

  const endDate = new Date(date + 'T23:59:59');
  const startDate = new Date(endDate.getTime() - days * 24 * 60 * 60 * 1000);

  const { query, setQuery } = useLocalFilterState();
  const [range, setRange] = useState<DateRangePickerProps.Value | null>({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    type: 'absolute',
  });
  const [page, setPage] = useLocalPageState();

  useEffect(() => {
    fetchApi();
  }, [date, days]);

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Overview', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<ZappyHeader title="Dashboard" />} headerVariant="high-contrast">
          <SpaceBetween direction="vertical" size="m">
            <Container>
              <SpaceBetween direction="vertical" size="xs">
                <DashboardFilter
                  date={date}
                  days={days}
                  fetchApi={fetchApi}
                  loading={loading}
                  setDate={setDate}
                  setDays={setDays}
                />
                <DashboardMetrics days={days} error={error} loading={loading} report={report} />
              </SpaceBetween>
            </Container>
            {error ? (
              <Alert
                action={
                  <Button disabled={loading} onClick={fetchApi}>
                    Retry
                  </Button>
                }
                header="Error retrieving dashboard report"
                statusIconAriaLabel="Error"
                type="error"
              >
                {error}
              </Alert>
            ) : undefined}
            {/* <VenueTable
              description={`Venue metrics within the last ${days} days`}
              devices={devices}
              error={error}
              fetchApi={fetchApi}
              loading={loading}
              orders={orders}
              pageSize={15}
              range={{
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                type: 'absolute',
              }}
              title="Venues"
            /> */}
            <ProvidedOrderTable
              description={`Zappy orders within the last ${days} days`}
              page={page}
              query={query}
              range={range}
              setPage={setPage}
              setQuery={setQuery}
              setRange={setRange}
              hideRange
            />
          </SpaceBetween>
        </ContentLayout>
      }
      contentType="dashboard"
      headerSelector=".top-navigation"
      headerVariant="high-contrast"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
