import { useEffect, useMemo, useState } from 'react';

import { OrderReport, DashboardReport } from '../common/types';
import { useApiNoBody } from '../common/api';

export function useDashboardState(
  report: DashboardReport | undefined,
  setReport: (report: DashboardReport) => void,
  date: string,
  days: number,
  setDate: (date: string) => void,
  setDays: (days: number) => void,
  route?: string,
) {
  let apiPath = route ? route : '/reports/dashboard';
  // add query params to apiPath
  const params = new URLSearchParams();

  // set date to local date with 23:59:59 time
  // then convert to UTC date
  const localDate = new Date(date + 'T23:59:59');
  params.append('end', localDate.toISOString());
  params.append('days', `${days}`);

  // TODO add query to dependency array and as query params
  apiPath += `?${params.toString()}`;

  const [api, error, loading] = useApiNoBody<DashboardReport>(apiPath, 'GET', (report) => {
    setReport(report);
  });

  const fetchApi = async () => {
    await api();
  };

  return {
    fetchApi,
    date,
    setDate,
    days,
    setDays,
    report,
    loading,
    error,
  };
}

export function useDashboard(route?: string) {
  const [report, setReport] = useState<DashboardReport>();
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  const [date, setDate] = useState(localISOTime.split('T')[0]);
  const [days, setDays] = useState(30);

  return useDashboardState(report, setReport, date, days, setDate, setDays, route);
}

export function useDashboardWithUrl(route?: string) {
  const [report, setReport] = useState<DashboardReport>();
  // TODO get date and days from URL
  console.warn('TODO get date and days from URL');
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  const [date, setDate] = useState(localISOTime.split('T')[0]);
  const [days, setDays] = useState(30);

  return useDashboardState(report, setReport, date, days, setDate, setDays, route);
}
