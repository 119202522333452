import {
  Container,
  SpaceBetween,
  Button,
  Alert,
  Header,
  ColumnLayout,
  Checkbox,
} from '@cloudscape-design/components';
import { useState } from 'react';

import { useApiNoBody, useApiNoResponse } from '../../../common/api';
import { RoleStatus, RoleUpdateRequest, ROLE_INFO, UserListResponse } from '../../../common/types';

export type UserDetailsProps = {
  user: UserListResponse;
  setUser: (user: UserListResponse) => void;
};

export function UserRolesInfo(props: UserDetailsProps) {
  const { user } = props;

  const [loading, setLoading] = useState(false);

  const [roles, setRoles] = useState<RoleStatus[]>(
    ROLE_INFO.map((role) => {
      return {
        ...role,
        enabled: user.roles.includes(role.name),
      };
    }),
  );

  const [rolesApi, rolesError, rolesLoading] = useApiNoResponse<RoleUpdateRequest>(
    `/users/${user.id}/roles`,
    'POST',
  );
  const [userApi, userError, userLoading] = useApiNoBody<UserListResponse>(
    '/users',
    'GET',
    props.setUser,
  );

  const error = rolesError || userError;

  const updateRoles = async () => {
    if (loading) return;
    setLoading(true);
    const response = await rolesApi({
      roles: roles.filter((role) => role.enabled).map((role) => role.name),
    });

    if (!response.error) {
      await userApi(undefined, user.uuid);
    }
    setLoading(false);
  };

  return (
    <SpaceBetween size="l">
      <Container
        header={
          <Header
            actions={
              <Button loading={loading} variant="normal" onClick={updateRoles}>
                Save
              </Button>
            }
            variant="h2"
          >
            Roles
          </Header>
        }
      >
        <ColumnLayout columns={1} variant="text-grid">
          {roles.map((role) => (
            <Checkbox
              key={`role-${role.name}-checkbox`}
              checked={role.enabled}
              disabled={loading || role.name === 'basic'}
              onChange={({ detail }) => {
                const newRoles = [...roles];

                for (const newRole of newRoles) {
                  if (newRole.name === role.name) {
                    newRole.enabled = detail.checked;
                  }
                }
                setRoles(newRoles);
              }}
            >
              {role.display}
            </Checkbox>
          ))}
        </ColumnLayout>
      </Container>
      {error ? (
        <Alert header="Role Save Error" statusIconAriaLabel="Error" type="error">
          {error}
        </Alert>
      ) : null}
    </SpaceBetween>
  );
}
