import { Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { Device } from '../common/types';

export type DeviceLinkProps = {
  device: Device;
};

export function DeviceLink(props: DeviceLinkProps) {
  const { device } = props;
  const navigate = useNavigate();

  return (
    <Link
      href={`/devices/${device.uuid}`}
      variant="secondary"
      onFollow={(e) => {
        e.preventDefault();
        navigate(`/devices/${device.uuid}`);
      }}
    >
      {device.device_id.trim()}
    </Link>
  );
}
