import axios from 'axios';
import { useContext, useEffect } from 'react';
import jwt from 'jwt-decode';

import UserContext from '../user';
import { TokenRefreshResponse, User, UserTokenInfo } from '../types';
import { ApiRequest, useRefreshApi } from '../api';

const useLive = false;

export const liveApiUrl = 'https://dashboard-api.needzappy.com';

export const apiUrl =
  (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && !useLive
    ? 'http://localhost:80'
    : liveApiUrl;

const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export async function getAccessTokenSilently(
  user: UserTokenInfo,
  setUser: (user: UserTokenInfo | null) => void,
  refreshApi: () => Promise<ApiRequest<TokenRefreshResponse>>,
) {
  if (user && user.exp < Date.now() / 1000) {
    const result = await refreshApi();

    if (result.error) {
      setUser(null);
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      console.error(result.error);
    } else if (result.data) {
      const newToken = result.data.token;
      localStorage.setItem('token', newToken);
      setUser(newToken ? (jwt(newToken) as UserTokenInfo) : null);
    }
  }

  return localStorage.getItem('token');
}

export const useAxiosInterceptors = () => {
  const { user, setUser } = useContext(UserContext);
  const [refreshApi] = useRefreshApi();

  useEffect(() => {
    const setAuthInterceptor = async () => {
      if (user) {
        axiosInstance.interceptors.request.use(async (config) => {
          try {
            const token = await getAccessTokenSilently(user, setUser, refreshApi);

            if (token) {
              config.headers.Authorization = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error getting access token:', error);
          }

          return config;
        });
      }
    };

    setAuthInterceptor();
  }, [getAccessTokenSilently, user, setUser]);
};

export default axiosInstance;
