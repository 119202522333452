import {
  PropertyFilterOption,
  PropertyFilterQuery,
  useCollection,
} from '@cloudscape-design/collection-hooks';
import {
  Box,
  Header,
  SpaceBetween,
  Button,
  Table,
  Pagination,
  PropertyFilter,
  DateRangePicker,
  DateRangePickerProps,
} from '@cloudscape-design/components';
import { ReactNode, useEffect } from 'react';

import { Order } from '../common/types';
import { getHeaderCounterText } from '../common/full-header';
import { PaymentStatusIcon, RentalStatusIcon, getDuration } from '../common/orders';
import { checkIsValidRange } from '../common/range';
import { getDurationString } from '../common/filter';
import { VenueLink } from '../venues/link';
import { DeviceLink } from '../devices/link';
import { exportExcel } from '../common/excel';

import { ORDER_FILTERS, useOrdersWithUrl } from './order-filtering';

export type OrderFilterRawProps = {
  query: PropertyFilterQuery;
  setQuery: (query: PropertyFilterQuery) => void;
  propertyFilteringOptions: PropertyFilterOption[];
  loading: boolean;
  range: DateRangePickerProps.Value | null;
  setRange: (range: DateRangePickerProps.Value | null) => void;
  skipColumns?: string[];
};

export function OrderFilterRaw(props: OrderFilterRawProps) {
  const { query, setQuery, propertyFilteringOptions, loading, range, setRange } = props;

  return (
    <SpaceBetween direction="horizontal" size="s">
      <PropertyFilter
        disabled={loading}
        filteringOptions={propertyFilteringOptions}
        filteringProperties={ORDER_FILTERS.filter((f) => !props.skipColumns?.includes(f.key))}
        query={query}
        onChange={({ detail }) => setQuery(detail)}
      />
      <DateRangePicker
        disabled={loading}
        isValidRange={checkIsValidRange}
        placeholder="Filter by a date and time range"
        relativeOptions={[
          {
            key: 'previous-12-hours',
            amount: 12,
            unit: 'hour',
            type: 'relative',
          },
          {
            key: 'previous-1-day',
            amount: 1,
            unit: 'day',
            type: 'relative',
          },
          {
            key: 'previous-1-week',
            amount: 1,
            unit: 'week',
            type: 'relative',
          },
          {
            key: 'previous-1-month',
            amount: 1,
            unit: 'month',
            type: 'relative',
          },
          {
            key: 'previous-1-year',
            amount: 1,
            unit: 'year',
            type: 'relative',
          },
        ]}
        value={range}
        onChange={({ detail }) => setRange(detail.value)}
      />
    </SpaceBetween>
  );
}

export function StandaloneOrderTableRaw() {
  const {
    fetchApi,
    range,
    setRange,
    query,
    setQuery,
    propertyFilteringOptions,
    filteredItems,
    loading,
    error,
  } = useOrdersWithUrl();

  // useEffect(() => {
  //   if (!range) {
  //     setRange({
  //       key: 'previous-1-month',
  //       amount: 1,
  //       unit: 'month',
  //       type: 'relative',
  //     });
  //   }
  // }, []);

  useEffect(() => {
    fetchApi();
  }, [range]);

  const itemFilter = (
    <OrderFilterRaw
      loading={loading}
      propertyFilteringOptions={propertyFilteringOptions}
      query={query}
      range={range}
      setQuery={setQuery}
      setRange={setRange}
    />
  );

  return (
    <OrderTableRaw
      error={error}
      fetchApi={fetchApi}
      filteredItems={filteredItems}
      itemFilter={itemFilter}
      loading={loading}
    />
  );
}

export type OrderTableRawProps = {
  fetchApi: () => void;
  filteredItems: Order[];
  loading: boolean;
  error: string | null;
  itemFilter?: ReactNode;
  actions?: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
  skipColumns?: string[];
};

export function OrderTableRaw(props: OrderTableRawProps) {
  const pageSize = 100;
  const resourceName = 'Order';

  const { fetchApi, filteredItems, loading, error } = props;

  const { items, collectionProps, paginationProps } = useCollection(filteredItems, {
    pagination: { pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'rented',
          sortingComparator(a, b) {
            const x = new Date(a.rent_time);
            const y = new Date(b.rent_time);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        isDescending: true,
      },
    },
  });

  return (
    <Table
      {...collectionProps}
      {...paginationProps}
      columnDefinitions={[
        {
          id: 'rented',
          header: 'Rented',
          cell: (e: Order) => new Date(e.rent_time + 'Z').toLocaleString(),
          sortingField: 'rented',
          sortingComparator(a: Order, b: Order) {
            const x = new Date(a.rent_time);
            const y = new Date(b.rent_time);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'payment_status',
          header: 'Payment Status',
          cell: (e: Order) => <PaymentStatusIcon status={e.payment_status} />,
          sortingField: 'payment_status',
          sortingComparator(a: Order, b: Order) {
            return a.payment_status.localeCompare(b.payment_status);
          },
        },
        {
          id: 'rental_status',
          header: 'Rental Status',
          cell: (e: Order) => <RentalStatusIcon status={e.rent_status} />,
          sortingField: 'rental_status',
          sortingComparator(a: Order, b: Order) {
            return a.rent_status.localeCompare(b.rent_status);
          },
        },
        {
          id: 'device',
          header: 'Device',
          cell: (e: Order) => <DeviceLink device={e.device} />,
          sortingField: 'device',
          sortingComparator(a: Order, b: Order) {
            return a.device.device_id.localeCompare(b.device.device_id);
          },
        },
        {
          id: 'venue',
          header: 'Venue',
          cell: (e: Order) => <VenueLink maxLength={20} venue={e.from_venue} />,
          sortingField: 'venue',
          sortingComparator(a: Order, b: Order) {
            return a.from_venue.name.localeCompare(b.from_venue.name);
          },
        },
        {
          id: 'source',
          header: 'Source',
          cell: (e: Order) => (e.order_type === 'Uber' ? 'Uber' : 'Kiosk'),
          sortingField: 'source',
          sortingComparator(a: Order, b: Order) {
            const x = a.order_type === 'Uber' ? 'Uber' : 'Kiosk';
            const y = b.order_type === 'Uber' ? 'Uber' : 'Kiosk';

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'revenue',
          header: 'Revenue',
          cell: (e: Order) => `$${parseFloat(e.settlement_amount).toFixed(2)} USD`,
          sortingField: 'revenue',
          sortingComparator(a: Order, b: Order) {
            const x = parseFloat(a.settlement_amount);
            const y = parseFloat(b.settlement_amount);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'duration',
          header: 'Duration',
          cell: (e: Order) => {
            const diff = getDuration(e);

            return getDurationString(diff);
          },
          sortingField: 'revenue',
          sortingComparator(a: Order, b: Order) {
            const x = getDuration(a) ?? 0;
            const y = getDuration(b) ?? 0;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
      ].filter((column) => !props.skipColumns?.includes(column.id))}
      empty={
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {error}
                </Box>
              </div>
              <Button onClick={fetchApi}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      }
      filter={props.itemFilter}
      header={
        <Header
          actions={
            props.actions ?? (
              <SpaceBetween direction="horizontal" size="s">
                <Button disabled={loading} iconName="refresh" onClick={fetchApi} />
                <Button
                  disabled={loading}
                  iconName="download"
                  onClick={() => {
                    exportExcel(
                      filteredItems.map((order) => ({
                        id: order.source_id,
                        order_no: order.order_number,
                        pos: order.order_type,
                        device_type: order.device.slots,
                        device_id: order.device.device_id,
                        slot: order.slot,
                        rent_time: new Date(order.rent_time + 'Z').toLocaleString(),
                        payment_status: order.payment_status,
                        rent_status: order.rent_status,
                        returned_time: order.returned_time
                          ? new Date(order.returned_time + 'Z').toLocaleString()
                          : '',
                        settlement_amount: order.settlement_amount,
                        from_store: order.from_venue.name,
                        return_store: order.return_venue ? order.return_venue.name : '',
                      })),
                      'orders',
                    );
                  }}
                />
              </SpaceBetween>
            )
          }
          counter={getHeaderCounterText(filteredItems, collectionProps.selectedItems)}
          description={props.description}
        >
          {props.title ?? `${resourceName}s`}
        </Header>
      }
      items={items}
      loading={loading}
      loadingText={`Loading ${resourceName.toLowerCase()}s`}
      pagination={<Pagination {...paginationProps} disabled={loading} />}
      trackBy="id"
    />
  );
}
