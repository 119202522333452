import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';

import { Order, OrderMeta } from '../types';

import { getQueryResource } from './query';
import axiosInstance from './axiosInstance';

export async function getVenueOrders(
  uuid: string,
  query?: PropertyFilterQuery,
  page = 1,
  limit = 50,
  range?: DateRangePickerProps.Value | null,
  now = new Date(),
) {
  return await getQueryResource<Order>(
    `/venues/${uuid}/orders`,
    query,
    page,
    limit,
    range,
    now,
    'rent_time',
  );
}

export async function getVenueOrdersMeta(uuid: string) {
  const response = await axiosInstance.get(`/venues/${uuid}/orders/meta`);

  return response.data as OrderMeta;
}
