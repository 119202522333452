import {
  Container,
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  SpaceBetween,
} from '@cloudscape-design/components';
import { useContext, useEffect } from 'react';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import { useOrdersWithUrl } from '../orders/order-filtering';
import { OrderReport } from '../report/report';
import { VenueTable } from '../report/venue';
import { useDevicesFromOrders } from '../common/stats';
import { OrderFilterRaw, OrderTableRaw } from '../orders/raw';

export default function Home() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const {
    fetchApi: fetchOrders,
    range,
    setRange,
    query,
    setQuery,
    propertyFilteringOptions,
    filteredItems: orders,
    loading: orderLoading,
    error: orderError,
  } = useOrdersWithUrl();

  // const {
  //   fetchApi: fetchUptime,
  //   filteredItems: uptimes,
  //   loading: uptimeLoading,
  //   error: uptimeError,
  // } = useUptimeFromOrders(orders, range, query);

  const { filteredItems: devices } = useDevicesFromOrders(orders);

  // const loading = orderLoading || uptimeLoading;
  // const error = orderError || uptimeError;
  const loading = orderLoading;
  const error = orderError;
  const fetchApi = async () => {
    await fetchOrders();
    // await fetchUptime();
  };

  // useEffect(() => {
  //   if (!range) {
  //     setRange({
  //       key: 'previous-1-month',
  //       amount: 1,
  //       unit: 'month',
  //       type: 'relative',
  //     });
  //   }
  // }, []);

  useEffect(() => {
    fetchApi();
  }, [range]);

  const itemFilter = (
    <OrderFilterRaw
      loading={loading}
      propertyFilteringOptions={propertyFilteringOptions}
      query={query}
      range={range}
      setQuery={setQuery}
      setRange={setRange}
    />
  );

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Reporting', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout
          header={
            <Grid
              gridDefinition={[
                { colspan: { default: 4, xxs: 3, xs: 2, m: 1 } },
                { colspan: { default: 8, xxs: 9, xs: 10, m: 11 } },
              ]}
            >
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <img
                  alt="Zappy Logo"
                  height="auto"
                  src="https://needzappy.com/wp-content/uploads/2023/09/zappy-logoAsset-1.svg"
                  style={{
                    maxHeight: '100px',
                    maxWidth: '100px',
                  }}
                  width="100%"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Header variant="h1">
                  <span
                    style={{
                      fontSize: '30px',
                      color: '#d5de24',
                    }}
                  >
                    Reporting
                  </span>
                </Header>
              </div>
            </Grid>
          }
          headerVariant="high-contrast"
        >
          <Grid
            gridDefinition={[
              { colspan: { default: 12, s: 5 } },
              { colspan: { default: 12, s: 7 } },
              { colspan: { default: 12 } },
            ]}
          >
            <SpaceBetween direction="vertical" size="s">
              <Container header={<Header>Report Settings</Header>}>{itemFilter}</Container>
              <OrderReport
                description="Overview for the specified time range"
                devices={devices}
                error={error}
                fetchApi={fetchApi}
                loading={loading}
                orders={orders}
                range={range}
                title="Overview"
              />
            </SpaceBetween>
            <SpaceBetween direction="vertical" size="s">
              <VenueTable
                description="Venue metrics within the specified time range"
                devices={devices}
                error={error}
                fetchApi={fetchApi}
                loading={loading}
                orders={orders}
                pageSize={15}
                range={range}
                title="Venues"
              />
              {/* <HoursMetrics
                description="Revenue and orders by hour within the specified time range"
                error={error}
                fetchApi={fetchApi}
                filteredItems={orders}
                loading={loading}
                title="Revenue by Hour"
              />
              <DaysMetrics
                description="Revenue and orders by day within the specified time range"
                error={error}
                fetchApi={fetchApi}
                filteredItems={orders}
                loading={loading}
                title="Revenue by Day"
              />
              <SourceDonut
                description="Revenue by source within the specified time range"
                error={error}
                fetchApi={fetchApi}
                filteredItems={orders}
                loading={loading}
              /> */}
            </SpaceBetween>
            <OrderTableRaw
              description="Zappy orders within the specified time range"
              error={error}
              fetchApi={fetchApi}
              filteredItems={orders}
              loading={loading}
            />
          </Grid>
        </ContentLayout>
      }
      contentType="dashboard"
      headerSelector=".top-navigation"
      headerVariant="high-contrast"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
