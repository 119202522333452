import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';

import { rangeStartEnd } from '../filter';

import axiosInstance from './axiosInstance';

export type PaginatedResponse<T> = {
  page: number;
  limit: number;
  total: number;
  pages: number;
  items: T[];
};

export async function getQueryResource<T>(
  route: string,
  query?: PropertyFilterQuery,
  page = 1,
  limit = 20,
  range?: DateRangePickerProps.Value | null,
  now = new Date(),
  rangeName?: string,
): Promise<PaginatedResponse<T>> {
  const operators = query ? query.tokens.map((token) => token.operator) : [];
  const propertyKeys = query ? query.tokens.map((token) => token.propertyKey as string) : [];
  const values = query ? query.tokens.map((token) => token.value as string) : [];

  if (range) {
    const { start, end } = rangeStartEnd(range, now);
    const startIso = start.toISOString();
    const endIso = end.toISOString();
    let rangePropertyKey = 'rent_time';

    if (rangeName) {
      rangePropertyKey = rangeName;
    }
    propertyKeys.push(rangePropertyKey);
    operators.push('>=');
    values.push(startIso);
    propertyKeys.push(rangePropertyKey);
    operators.push('<=');
    values.push(endIso);
  }

  const op = query ? (query.operation === 'or' ? 'or' : undefined) : undefined;
  const response = await axiosInstance.get(route, {
    params: {
      op,
      o: operators,
      k: propertyKeys,
      v: values,
      page,
      limit,
    },
    // Get rid of [] in query params
    // https://stackoverflow.com/questions/42898009/multiple-fields-with-same-key-in-query-params-axios-request
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });

  return response.data as PaginatedResponse<T>;
}
